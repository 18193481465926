import { Typography } from '@mui/material'
import { Step } from 'react-joyride'
import { Link } from 'react-router-dom'

export const lastStep: Step = {
  target: 'body',
  content: (
    <>
      <ConfettiIcon />
      <Typography sx={{ mb: 3 }} variant="label" component="p">
        Congratulations!
      </Typography>
      <Typography variant="paragraph2">
        You've reached the end of the tour! You can find more tours{' '}
        <Link to="/panel/help">here</Link>
      </Typography>
    </>
  ),
  placement: 'center',
}

function ConfettiIcon() {
  return (
    <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      <title />
      <g data-name="Confetti Papaer" id="666af6be-c8d3-4fb5-ab4a-d11023bf843b">
        <path
          d="M89.52,111.23,84.85,106s8-3.11,8.93-8C93.78,98,97,104.56,89.52,111.23Z"
          fill="#de8b4e"
        />
        <path
          d="M48.6,62.45s4.77,22.92,18.25,18.1,7.91-36.64-1.65-33.08,16.91,33,26.56,10.62S75.5,22.29,72.18,30.23s19.51,22,34.54,4.51"
          fill="none"
          stroke="#fe9f59"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          d="M27.69,16.91S9.15,15.33,9.74,26.7,35.86,41.06,35.32,33s-29,5.36-14.13,17.82,31-4.24,25.69-8.58S25.65,52.06,35.58,67.5"
          fill="none"
          stroke="#fe5959"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          d="M36.35,94.91S30.57,106,37.8,108.61s15.7-12.54,10.52-14.3-4.17,19.46,7.44,13.41,5.37-20.38,1.3-18.21S60,109,77,103"
          fill="none"
          stroke="#7688f1"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <polygon
          fill="#fe9f59"
          points="42.2 18.83 41.91 24.55 48.8 26.81 47.92 19.12 42.2 18.83"
        />
        <polygon
          fill="#fe5959"
          points="98.26 61.79 96.3 65.37 100.03 68.95 101.84 63.74 98.26 61.79"
        />
        <polygon
          fill="#fe9f59"
          points="104.2 52.83 103.91 58.55 107 59 109.92 53.12 104.2 52.83"
        />
        <polygon
          fill="#fe9f59"
          points="38.95 81.76 43.91 78.89 42.05 71.88 36.08 76.8 38.95 81.76"
        />
        <polygon
          fill="#fe5959"
          points="26.01 64.36 24.24 58.92 16.99 59.27 20.56 66.14 26.01 64.36"
        />
        <polygon
          fill="#7681c2"
          points="71.92 89.98 75.42 87.95 74.11 83 69.89 86.48 71.92 89.98"
        />
        <polygon
          fill="#7681c2"
          points="20.6 73.17 18.18 69.93 13.42 71.81 17.36 75.59 20.6 73.17"
        />
        <path
          d="M98.48,89.77,103.15,95s-8,3.11-8.93,8C94.22,103,91,96.44,98.48,89.77Z"
          fill="#fe9f59"
        />
        <path
          d="M41.22,85.46,40,92.34S32.64,88,28.17,90.2C28.17,90.2,31.38,83.67,41.22,85.46Z"
          fill="#de5050"
        />
        <path
          d="M18.78,91.54,20,84.66S27.36,89,31.83,86.8C31.83,86.8,28.62,93.33,18.78,91.54Z"
          fill="#fe5959"
        />
        <path
          d="M46,38.39l.72-7s7.65,3.81,11.94,1.24C58.7,32.67,56,39.42,46,38.39Z"
          fill="#7681c2"
        />
        <path
          d="M68,30.61l-.72,7s-7.65-3.81-11.94-1.24C55.3,36.33,58,29.58,68,30.61Z"
          fill="#5f6dc2"
        />
        <path
          d="M92.27,29.19l-2.72-6.45s8.54-.35,11.06-4.66C100.6,18.07,101.48,25.3,92.27,29.19Z"
          fill="#7681c2"
        />
        <circle cx="62.5" cy="66.5" fill="#7681c2" r="1.5" />
        <circle cx="66.5" cy="21.5" fill="#7681c2" r="2" />
        <circle cx="80.5" cy="94.5" fill="#fe9f59" r="1.5" />
        <circle cx="49.5" cy="14.5" fill="#fe9f59" r="1.5" />
        <circle cx="12.5" cy="54.5" fill="#fe9f59" r="1.5" />
        <circle cx="104.5" cy="48.5" fill="#fe5959" r="1.5" />
        <circle cx="51.5" cy="54.5" fill="#fe5959" r="1.5" />
        <circle cx="88.5" cy="76.5" fill="#fe5959" r="2.5" />
        <path
          d="M94,77s1,10,9,9,14,0,13,9-6,10-6,10l5-2v6s7-11,5-19-16-5-16-5-4-2-2-6Z"
          fill="#fe5959"
        />
        <path
          d="M33,72s-1,10-9,9-14,0-13,9,6,10,6,10l-5-2v6S5,93,7,85s16-5,16-5,4-2,2-6Z"
          fill="#fe9f59"
        />
      </g>
    </svg>
  )
}
