import { fileAddAction } from '~api/files/add'
import { fileDeleteAction } from '~api/files/delete'
import {
  filesAllAction,
  filesReadAction,
  vettingFilesReadAction,
} from '~api/files/read'
import { fileUpdateAction } from '~api/files/update'
import { poolPartnerAddAction } from '~api/pool-partner/add'
import { poolPartnerUpdateAction } from '~api/pool-partner/update'
import { poolPartnersAllAction } from '~api/pool-partners/all'
import { userCreateAction, userInviteAction } from '~api/user/add'
import { userInfoEditAction } from '~api/user/info/edit'
import { userInfoReadAction } from '~api/user/info/read'
import { userInviteReadAction } from '~api/user/read'
import { userRoleAddAction } from '~api/user/roles/add'
import { userRoleDeleteAction } from '~api/user/roles/delete'
import { userRolesReadAction } from '~api/user/roles/read'
import { userTermsUpdateAction } from '~api/user/update'
import { usersAllAction } from '~api/users/all'
import {
  usersReadByPoolPartnerAction,
  usersReadByVesselIdAction,
} from '~api/users/read'
import {
  vesselsQuestionnaireApproveAction,
  vesselsQuestionnaireDownloadFileAction,
  vesselsQuestionnaireRejectAction,
  vesselsQuestionnaireRemoveFileAction,
  vesselsQuestionnaireUpdateAction,
  vesselsQuestionnaireUploadFileAction,
} from '~api/vessels/questionnaire/update'
import {
  vesselStatusUpdateAction,
  vesselsUpdateAction,
} from '~api/vessels/update'
import { documentAddAction } from '~api/vessels/vessel-tabs/documents/add'
import {
  documentsRemoveFileAction,
  documentsUpdateAction,
} from '~api/vessels/vessel-tabs/documents/update'
import { vesselTabsChangeRequestAction } from '~api/vessels/vessel-tabs/request'
import { vesselTabsUpdateAction } from '~api/vessels/vessel-tabs/update'
import { versionHistoryReadAction } from '~api/vessels/vessel-tabs/version-history-read'
import { voyagesCSVFileReadAction } from '~api/vessels/vessel-tabs/voyages/all'
import {
  vesselTabsAddRejectionAction,
  vesselTabsAddSireCdiAction,
} from '~api/vettings/add'
import { vettingCSVFileReadAction } from '~api/vettings/overview/read'
import {
  vesselTabsEditRejectionAction,
  vesselTabsEditSireCdiAction,
} from '~api/vettings/update'

import {
  downloadBulkCertificatesAction,
  downloadCertificateAction,
  requestDownloadingCertificateAction,
} from './vessels/vessel-tabs/documents/read'

export { ApiConfig } from '~api/config'
export { ApiRoutes } from '~api/routes'

export const ApiClient = {
  Files: {
    all: filesAllAction,
    read: filesReadAction,
    add: fileAddAction,
    update: fileUpdateAction,
    delete: fileDeleteAction,
    vettingFilesRead: vettingFilesReadAction,
  },

  PoolPartners: {
    all: poolPartnersAllAction,
    add: poolPartnerAddAction,
    update: poolPartnerUpdateAction,
  },
  Vessels: {
    update: vesselsUpdateAction,
    status: vesselStatusUpdateAction,
    Documents: {
      update: documentsUpdateAction,
      add: documentAddAction,
      delete: documentsRemoveFileAction,
    },
    Questionnaire: {
      approve: vesselsQuestionnaireApproveAction,
      downloadFile: vesselsQuestionnaireDownloadFileAction,
      reject: vesselsQuestionnaireRejectAction,
      update: vesselsQuestionnaireUpdateAction,
      uploadFile: vesselsQuestionnaireUploadFileAction,
      removeFile: vesselsQuestionnaireRemoveFileAction,
    },
    Tabs: {
      update: vesselTabsUpdateAction,
      changeRequest: vesselTabsChangeRequestAction,
      versionHistoryRead: versionHistoryReadAction,
      addRejection: vesselTabsAddRejectionAction,
      editRejection: vesselTabsEditRejectionAction,
      addSireCdi: vesselTabsAddSireCdiAction,
      editSireCdi: vesselTabsEditSireCdiAction,
    },
    Certificates: {
      downloadCertificate: downloadCertificateAction,
      requestDownloadingCertificate: requestDownloadingCertificateAction,
      downloadBulkCertificates: downloadBulkCertificatesAction,
    },
  },
  User: {
    Roles: {
      add: userRoleAddAction,
      delete: userRoleDeleteAction,
      read: userRolesReadAction,
    },
    Info: {
      read: userInfoReadAction,
      edit: userInfoEditAction,
    },
    create: userCreateAction,
    readInvite: userInviteReadAction,
    edit: userTermsUpdateAction,
    invite: userInviteAction,
  },
  Users: {
    all: usersAllAction,
    readByImo: usersReadByVesselIdAction,
    readByPoolPartner: usersReadByPoolPartnerAction,
    Managers: {
      all: usersAllAction,
    },
  },
  Vettings: {
    Overview: {
      readCSV: vettingCSVFileReadAction,
    },
  },
  Voyages: {
    readCSV: voyagesCSVFileReadAction,
  },
}
