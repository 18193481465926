import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

export interface MuiPaginationData {
  defaultProps?: ComponentsProps['MuiPagination']
  styleOverrides?: ComponentsOverrides['MuiPagination']
  variants?: ComponentsVariants['MuiPagination']
}

export const MuiPagination: MuiPaginationData = {
  styleOverrides: {
    root: {
      '& .MuiPaginationItem-root': {
        color: COLORS.greys.dark,
        borderColor: COLORS.greys.light,
        backgroundColor: COLORS.primary.white,
      },
      '& .Mui-selected': {
        color: COLORS.primary.maerskBlue,
        borderColor: COLORS.secondary.lightBlue,
        backgroundColor: COLORS.greys.ultraLight,
      },
      '& .MuiPaginationItem-previousNext': {
        border: 'none',
      },
    },
  },
}
