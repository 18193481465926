import { Box, Typography } from '@mui/material'
import { GoBackButton } from '~components/go-back-button'

export default function ErrorPage() {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        mx: { xs: 4, md: 0 },
      }}
    >
      <Box>
        <Typography variant="h1" sx={{ mt: { xs: 4, md: 8 }, mb: 5 }}>
          We are sorry!
        </Typography>
        <Typography sx={{ maxWidth: '520px', mb: 3 }}>
          There was an unexpected error. Please reload the page or click on the
          button below to go back to the home page.
        </Typography>
        <Typography sx={{ maxWidth: '520px', mb: 5, fontWeight: 'bold' }}>
          Want to report the issue? Click the "Report an Issue" widget on the
          right.
        </Typography>
        <GoBackButton forceHome />
      </Box>
    </Box>
  )
}
