import {
  Button,
  CloseIcon,
  TextButton,
} from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'
import { TooltipRenderProps } from 'react-joyride'
import { COLORS } from '~theme/colors'

export function CustomTooltip(props: TooltipRenderProps) {
  const {
    backProps,
    closeProps,
    continuous,
    index,
    primaryProps,
    step,
    tooltipProps,
    size,
    skipProps,
  } = props

  const isLastStep = index + 1 === size
  const showSkipTourButton = skipProps && !isLastStep

  return (
    <Box
      sx={{
        background: COLORS.primary.white,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '4px',
        width: '320px',
        height: { xs: '70vh', lg: 'initial' },
      }}
      {...tooltipProps}
    >
      <Box
        component="button"
        {...closeProps}
        sx={{
          ml: 'auto',
          mt: 1,
          mr: 1,
          backgroundColor: 'transparent',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          border: 'none',
          '&:hover': {
            backgroundColor: COLORS.greys.ultraLight,
          },
        }}
      >
        <CloseIcon fontSize="20px" color={COLORS.secondary.darkBlue} />
      </Box>
      <Box
        sx={{
          p: 4,
          pt: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 4, lg: 2 },
          mt: -4,
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 4, lg: 2 },
          }}
        >
          <Typography variant="caption">
            Step {index + 1} of {size}
          </Typography>
          {step.title && <Typography variant="h4">{step.title}</Typography>}
          <div>{step.content}</div>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: showSkipTourButton ? 'space-between' : 'end',
            flexWrap: 'wrap',
            mt: 2,
          }}
        >
          {showSkipTourButton && (
            <TextButton {...skipProps}>Skip tour</TextButton>
          )}
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'end',
              flexWrap: 'wrap',
            }}
          >
            {index > 0 && <TextButton {...backProps}>Back</TextButton>}
            {continuous && !isLastStep && (
              <Button variant="secondary" {...primaryProps}>
                Next
              </Button>
            )}
            {(!continuous || isLastStep) && (
              <Button {...closeProps}>Close</Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
