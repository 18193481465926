import { AlertProps } from '@maersktankersdigital/web-components'
import type {
  Context,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
} from 'react'
import { createContext, useState } from 'react'
import createContextStateHook from '~utils/create-hook-for-context-state'

export type AnimatedAlertState = {
  delay?: number
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  setStatus: (value: string, delay?: number, newVariant?: AlertVariant) => void
  setText: Dispatch<SetStateAction<string>>
  setVariant: Dispatch<SetStateAction<AlertVariant>>
  text: string
  variant: AlertVariant
}

export type AlertVariant = AlertProps['variant']

export type TSetStatus = (
  value: string,
  delay?: number,
  variant?: AlertVariant,
) => void

export const AnimatedAlertContext = createContext<AnimatedAlertState | null>(
  null,
) as Context<AnimatedAlertState>

export default function AnimatedAlertProvider({ children }: PropsWithChildren) {
  const [text, setText] = useState('')
  const [variant, setVariant] = useState<AlertVariant>('success')
  const [isVisible, setIsVisible] = useState(false)
  const [delay, setDelay] = useState<number>()

  const setStatus: TSetStatus = (value, delay = 5000, newVariant) => {
    setText(value)
    setVariant(newVariant || variant)
    setIsVisible(true)
    setDelay(delay)
    setTimeout(() => {
      setText('')
      setIsVisible(false)
    }, delay)
  }

  return (
    <AnimatedAlertContext.Provider
      value={{
        setText,
        setVariant,
        setIsVisible,
        setStatus,
        isVisible,
        variant,
        delay,
        text,
      }}
    >
      {children}
    </AnimatedAlertContext.Provider>
  )
}

export const useAnimatedAlert = createContextStateHook(
  AnimatedAlertContext,
  'AnimatedAlertContext',
)
