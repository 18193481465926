import { Loader } from '@maersktankersdigital/web-components'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import { mutate } from 'swr'

import { ApiClient, ApiRoutes } from '~api'
import {
  IQuestionnaireCategory,
  IQuestionnaireField,
  IQuestionnairesResponse,
  IVesselFile,
} from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { Icon } from '~components/atoms/icon'
import { Link } from '~components/atoms/link'
import { Text } from '~components/atoms/text'
import { FileBox, IFileBox } from '~components/molecules/file-box/file-box'
import { IQuestionnaireListSection } from '~components/organisms/lists/vessel-questionnaires'
import { IVesselQuestionaireFieldTypeResolverRights } from '~components/organisms/modals/earnings-simulator/questionnaire-field'
import { generatePathName } from '~utils/generate-path-name'

export interface IVesselFileField {
  files?: IVesselFile[]
  isEditing?: boolean
  name: string
  section: IQuestionnaireListSection
  setIsCompleted?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IVesselFormFile extends IVesselFile {
  isLoading: boolean
  status: IFileBox['status']
}

const findQuestionnaireField = (
  response: IQuestionnairesResponse,
  name: string,
): IQuestionnaireField | undefined => {
  let foundField = undefined
  response.forEach((category: IQuestionnaireCategory) => {
    return category.sections.forEach((section) => {
      return section.fields.forEach((field) => {
        if (field.id === name) {
          foundField = field
        }
      })
    })
  })

  return foundField
}

const setInitialFiles = (
  isApprovalRequired: boolean,
  files?: IVesselFile[],
  userRights?: IVesselQuestionaireFieldTypeResolverRights,
) => {
  return (
    files?.map((file) => {
      return {
        ...file,
        isLoading: false,
        status: Boolean(file.approvedBy)
          ? 'approved'
          : !!userRights?.canApprove && isApprovalRequired
            ? 'approve_or_reject'
            : 'uploaded',
      } as IVesselFormFile
    }) ?? []
  )
}

export const VesselFileField: FC<IVesselFileField> = ({
  name,
  section,
  isEditing,
  setIsCompleted,
}) => {
  const field = section.fields.find((sectionField) => {
    return sectionField.id === name
  })

  const [files, initializeFilesState] = useState<IVesselFormFile[]>(
    setInitialFiles(
      field?.approvalRequired || false,
      field?.files,
      section.userRights,
    ),
  )
  const [isAdditionalFileNeeded, setAdditionalFileFlag] = useState<boolean>(
    files.length === 0,
  )
  const [isFileRemoving, setFileRemovingFlag] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isEditingLocal, setIsEditingLocal] = useState<boolean | undefined>(
    false,
  )
  const updateFile = (updatedFile: IVesselFormFile) => {
    initializeFilesState(
      files.map((file) => {
        return file.name !== updatedFile.name ? file : updatedFile
      }),
    )
  }

  useEffect(() => {
    setIsEditingLocal(isEditing)
  }, [isEditing])

  const removeFile = async (obsoleteFile: IVesselFile) => {
    if (obsoleteFile) {
      setFileRemovingFlag(true)
      setIsUploading(true)
      const urlParams = {
        vesselId,
        fileName: encodeURIComponent(obsoleteFile.filename),
        id: name,
      }
      await ApiClient.Vessels.Questionnaire.removeFile(urlParams)
        .then((response) => {
          const uploadedField = findQuestionnaireField(response, name)

          initializeFilesState(
            setInitialFiles(
              uploadedField?.approvalRequired || false,
              uploadedField?.files,
              section.userRights,
            ),
          )
          mutate(
            generatePathName(ApiRoutes.Vessels.Questionnaire.read, {
              vesselId,
            }),
          )
          if (files.length > 0) {
            setAdditionalFileFlag(true)
          }
          setFileRemovingFlag(false)
          setIsUploading(false)
        })
        .catch(() => {
          setFileRemovingFlag(false)
          setIsUploading(false)
        })
    }
  }

  const { register } = useFormContext()
  const params = useParams()
  const { search } = useLocation()
  let vesselId = params.vesselId
  if (!vesselId) {
    const query = new URLSearchParams(search)
    const queryParams = Object.fromEntries(query.entries())
    vesselId = queryParams.vesselId
  }
  register(name)

  const onUpload = async (file?: File) => {
    if (file) {
      setIsUploading(true)
      const payload = {
        file,
        id: name,
      }

      await ApiClient.Vessels.Questionnaire.uploadFile(payload, {
        vesselId,
      })
        .then((response) => {
          const uploadedField = findQuestionnaireField(response, name)

          initializeFilesState(
            setInitialFiles(
              uploadedField?.approvalRequired || false,
              uploadedField?.files,
              section.userRights,
            ),
          )
          setIsUploading(false)
          setAdditionalFileFlag(false)
          mutate(
            generatePathName(ApiRoutes.Vessels.Questionnaire.read, {
              vesselId,
            }),
          )
        })
        .catch(() => {
          setIsUploading(false)
        })
    }
  }

  const handleRemove = async (file?: IVesselFile) => {
    if (file) {
      removeFile(file)
    }
  }

  const onApprove = async (file: IVesselFile) => {
    updateFile({ ...file, isLoading: true, status: 'approved' })
    const payload = {
      id: section.id,
      fields: [
        {
          id: name,
          files: [file],
        },
      ],
    }
    await ApiClient.Vessels.Questionnaire.approve(payload, {
      vesselId,
    })
      .then((response) => {
        setIsEditingLocal(false)
        const uploadedField = findQuestionnaireField(response, name)
        initializeFilesState(
          setInitialFiles(
            uploadedField?.approvalRequired || false,
            uploadedField?.files ?? [],
            section.userRights,
          ),
        )
        mutate(
          generatePathName(ApiRoutes.Vessels.Questionnaire.read, { vesselId }),
        )
      })
      .catch(() => {
        updateFile({ ...file, isLoading: false, status: 'approve_or_reject' })
      })
  }

  const onReject = async (file: IVesselFile) => {
    updateFile({ ...file, isLoading: true, status: 'uploaded' })
    const payload = {
      id: section.id,
      fields: [
        {
          id: name,
          files: [file],
        },
      ],
    }
    await ApiClient.Vessels.Questionnaire.reject(payload, {
      vesselId,
    })
      .then((response) => {
        const uploadedField = findQuestionnaireField(response, name)
        initializeFilesState(
          setInitialFiles(
            uploadedField?.approvalRequired || false,
            uploadedField?.files ?? [],
            section.userRights,
          ),
        )
        mutate(
          generatePathName(ApiRoutes.Vessels.Questionnaire.read, { vesselId }),
        )
        if (
          uploadedField?.files === undefined ||
          uploadedField?.files.length === 0
        ) {
          setAdditionalFileFlag(true)
        }
      })
      .catch(() => {
        updateFile({ ...file, isLoading: false, status: 'approve_or_reject' })
      })
  }

  const handleDownload = async (file?: IVesselFile) => {
    if (file) {
      await ApiClient.Vessels.Questionnaire.downloadFile({
        vesselId: file.metadata.vesselId,
        fileId: file.metadata.id,
        fileName: file.filename,
      }).then((response) => {
        if (response.signedUrl) {
          window.location.href = response.signedUrl
        }
      })
    }
  }

  return !isFileRemoving ? (
    <>
      {files.map((file) => {
        return (
          <Box key={file.name}>
            <FileBox
              name={file.name}
              file={file}
              status={isEditingLocal ? 'approve_or_reject' : file.status}
              onApprove={() => {
                onApprove(file)
                setIsCompleted?.(true)
              }}
              onReject={() => {
                onReject(file)
                setIsCompleted?.(true)
              }}
              onDownload={handleDownload}
              isLoading={file.isLoading}
              onRemove={handleRemove}
              canEdit={
                !!(
                  section.userRights?.canEdit || section.userRights?.canApprove
                )
              }
              canApprove={section.userRights?.canApprove}
              isApprovalRequired={field?.approvalRequired || false}
            />
          </Box>
        )
      })}
      {!!(section.userRights?.canEdit || section.userRights?.canApprove) && (
        <Box>
          {isAdditionalFileNeeded ? (
            <FileBox
              name="newFile"
              status="empty"
              isLoading={isUploading}
              onUpload={onUpload}
            />
          ) : (
            <Link
              onClick={() => {
                return setAdditionalFileFlag(true)
              }}
              noUnderline
            >
              <Box flexDirection="row" alignItems="center">
                <Icon name="plus" size={24} />
                <Box ml={1}>
                  <Text
                    text="Add additional file for upload"
                    color="blues.mid"
                  />
                </Box>
              </Box>
            </Link>
          )}
        </Box>
      )}
    </>
  ) : (
    <Loader />
  )
}
