import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useNavigate } from 'react-router-dom'
import LoadComponent from '~components/molecules/load-component/load-component'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import ErrorPage from '~pages/error-page'
import { apiBase } from '../utils/base-url'

export interface FetchAuth0ConfigRes {
  audience: string
  domain: string
  clientId: string
  scope: string
}

export function fetchAuth0Config(): Promise<FetchAuth0ConfigRes> {
  const requestUrl = `${apiBase()}/auth0`
  return fetch(requestUrl).then((response) => {
    if (response.ok) {
      return response.json()
    }
    throw new Error(response.statusText)
  })
}

export function Auth0ProviderWithConfig({
  children,
}: {
  children: React.ReactNode
}) {
  const navigate = useNavigate()
  const { data, isLoading, isError } = useQuery({
    queryKey: ['/auth0'],
    queryFn: fetchAuth0Config,
  })
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || '/')
  }

  if (isLoading) {
    return <LoadComponent />
  }

  return isError || !data ? (
    <ErrorPage />
  ) : (
    <Auth0Provider
      domain={data.domain}
      clientId={data.clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/callback`,
        audience: data.audience,
        scope: data.scope,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      <TrackUser />
      {children}
    </Auth0Provider>
  )
}

function TrackUser() {
  const { isAuthenticated, isLoading: isLoadingAuth0 } = useAuth0()
  const { data } = useGetMe({ isDisabled: !isAuthenticated })

  useEffect(() => {
    if (isLoadingAuth0) return

    if (!isAuthenticated) {
      const tagManagerArgs = {
        dataLayer: {
          department: 'not logged in',
          user_type: 'not logged in',
          custom_user_id: 'not logged in',
        },
      }
      TagManager.dataLayer(tagManagerArgs)
      return
    }

    if (data) {
      const tagManagerArgs = {
        dataLayer: {
          department: `${!data.isInternal ? 'external' : data.department || 'department not set'}`,
          user_type: `${data.isInternal ? 'internal' : 'external'}`,
          custom_user_id: `${data.sub}`,
        },
      }
      TagManager.dataLayer(tagManagerArgs)
    }
  }, [isLoadingAuth0, isAuthenticated, data])

  return null
}
