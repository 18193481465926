import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export interface GetWelcomePopupRes {
  isEnabled: boolean
  releaseDate: string
  version: string
  title: string
  description: string
  changelog: any[]
  incomingFeatures: any[]
  footer: string
  imageUrl: string
  imageName: string
  videoLink?: string
}

export function useGetWelcomePopup() {
  const fetchRequest = useFetch<GetWelcomePopupRes>()

  return useQuery({
    queryKey: ['/welcome-popup'],
    queryFn: async () => fetchRequest(`${apiBase()}/welcome-popup`),
    staleTime: tenMinutes,
  })
}
