import { PageRoute } from '~routing/constants/page-route'

import { GetVesselRes } from '~hooks/queries/vessels/use-get-vessel'
import { MTPPUsersGuard } from '~components/organisms/guards/mtppuser-guard'
import { generatePathName } from '~utils/generate-path-name'

import { HeaderDetail } from './header-details'

export const group1 = (vesselData: GetVesselRes): HeaderDetail[] => [
  {
    label: 'Pool Partner:',
    value: vesselData.poolPartner,
    routerLink: generatePathName(PageRoute.POOL_PARTNER, {
      poolPartnerKey: vesselData.poolPartner,
    }),
    guard: {
      component: MTPPUsersGuard,
    },
  },
  {
    label: 'Maersk Tankers PIC:',
    value: vesselData.partnerManagerUser
      ? (vesselData.partnerManagerUser.name ?? '')
      : '',
  },
]
export const group2 = (vesselData: GetVesselRes): HeaderDetail[] => [
  {
    label: 'Pool:',
    value: vesselData.pool,
  },

  {
    label: 'Ops coordinator:',
    value: vesselData.opsCoordinatorName ?? '',
  },
]
export const group3 = (vesselData: GetVesselRes): HeaderDetail[] => [
  {
    label: 'IMO:',
    value: vesselData.imoNumber ?? '',
  },
  {
    label: 'EUA account name:',
    value: vesselData?.euaInfo?.euaAccountName ?? '',
  },
  {
    label: 'EUA account number:',
    value: vesselData?.euaInfo?.euaAccountNumber ?? '',
  },
]

export const getHeaderDetailsContent = ({
  vesselData,
  isXlBreakpoint,
}: {
  isXlBreakpoint?: boolean
  vesselData: GetVesselRes
}): HeaderDetail[][] => {
  return !isXlBreakpoint
    ? [[...group1(vesselData), ...group2(vesselData)], group3(vesselData)]
    : [group1(vesselData), group2(vesselData), group3(vesselData)]
}
