import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export interface GetPoolsResItem {
  id: number
  name: string
}

export function useGetPools() {
  const { isAuthenticated } = useAuth0()

  const fetchRequest = useFetch<GetPoolsResItem[]>()
  return useQuery({
    queryKey: ['/pools'],
    queryFn: () => fetchRequest(`${apiBase()}/pools`),
    staleTime: tenMinutes,
    enabled: isAuthenticated,
  })
}
