import { sortItems } from '~components/navigation-and-footer/navigation/top-navigation/utils'
import { useGetMe } from './queries/me/use-get-me'

export function usePoolSelectOptions() {
  const { data } = useGetMe()
  const options = !data?.activePools
    ? []
    : data.activePools.map((pool) => ({
        label: pool,
        value: pool,
      }))
  return sortItems(options, (option) => option.label)
}
