import {
  ChevronLeftIcon,
  TextButton,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useConstructLinkWithPool } from '../hooks/use-construct-link-with-pool'

interface Props {
  className?: string
  forceHome?: boolean
}

export function GoBackButton({ className, forceHome = false }: Props) {
  const navigate = useNavigate()
  const dashboardLink = useConstructLinkWithPool('/panel/dashboard/:pool')

  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
      }}
      component={TextButton}
      icon={<ChevronLeftIcon fontSize={20} />}
      onClick={() => (forceHome ? navigate(dashboardLink) : navigate(-1))}
      className={className}
    >
      Go Back {forceHome ? 'to Dashboard' : ''}
    </Box>
  )
}
