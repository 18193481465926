import styled from 'styled-components/macro'

import { Colors, Fonts } from '~theme/deprecated-vt/deprecated-vt-theme'

interface IStyledInfoLabel {
  currentRejection?: boolean | string
  isQ88Source?: boolean
  labelColor?: string
}

const StyledInfoLabel = styled.span`
  text-transform: none;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  align-items: center;
  white-space: nowrap;
  font-family: ${Fonts.families.regular};
  font-size: ${Fonts.sizes.caption};
  line-height: ${Fonts.lineHeights.caption};
  color: ${Colors.body};
`

export const StyledRejectionLabel = styled(StyledInfoLabel)<IStyledInfoLabel>`
  background-color: ${(props) => {
    return props.currentRejection ? Colors.reds.base : Colors.greys.lightest
  }};
  color: ${(props) => {
    return props.currentRejection ? Colors.white : Colors.greys.dark
  }};
`

export const StyledDocumentsTabLabel = styled(
  StyledInfoLabel,
)<IStyledInfoLabel>`
  background-color: ${(props) => {
    return props.isQ88Source ? Colors.blues.maersk : Colors.blues.dark
  }};
  line-height: ${Fonts.lineHeights.small};
  color: ${Colors.white};
  padding: 2px 16px;
  text-transform: uppercase;
`

export const StyledVesselOnboardedLabel = styled(StyledInfoLabel)`
  background-color: ${Colors.greens.lighter};
  color: ${Colors.white};
  display: inline-flex;
`
export const StyledActiveCDIsLabel = styled(StyledInfoLabel)`
  background-color: ${Colors.greens.teal};
  color: ${Colors.white};
`

export const StyledVettingLoadLabel = styled(StyledInfoLabel)`
  background-color: #d7c3c1;
`
export const StyledVettingIdleLabel = styled(StyledInfoLabel)`
  background-color: #fbe7c3;
`
export const StyledVettingUnplannedLabel = styled(StyledInfoLabel)`
  background-color: ${Colors.greys.lightest};
  color: ${Colors.greys.dark};
`
export const StyledVettingDischargeLabel = styled(StyledInfoLabel)`
  background-color: ${Colors.greys.lightest};
  color: ${Colors.greys.dark};
`
export const StyledVettingRemoteLabel = styled(StyledInfoLabel)`
  background-color: ${Colors.greys.darker};
  color: ${Colors.white};
`
export const StyledVettingNALabel = styled(StyledInfoLabel)`
  background-color: transparent;
  font-size: ${Fonts.sizes.small};
`

export const StyledBulletLabel = styled(StyledInfoLabel)<IStyledInfoLabel>`
  position: relative;
  background-color: transparent;
  padding: 0 0 0 8px;
  color: ${Colors.white};
  line-height: 24px;
  &:before {
    position: absolute;
    top: 9px;
    left: -8px;
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 1px;
    background-color: ${(props) => props.labelColor ?? Colors.blues.maersk};
  }
`

export const StyledDashboardLabel = styled(StyledInfoLabel)<IStyledInfoLabel>`
  background-color: ${(props) => props.labelColor ?? Colors.oranges.dark};
  font-size: ${Fonts.sizes.body};
  line-height: 16px;
  text-transform: uppercase;
  color: ${Colors.white};
  padding: 4px 10px;
`
