import type {
  Context,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
} from 'react'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { GetVesselRes } from '~hooks/queries/vessels/use-get-vessel'
import { IDocumentsRowData } from '~api/vessels/vessel-tabs/documents/all'
import { VesselStatus } from '~types/vessel.types'
import createContextStateHook from '~utils/create-hook-for-context-state'

export type VesselPageState = {
  exportToExcel: boolean
  isBulkSelectActive: boolean
  isVesselArchived: boolean
  isVesselOnboarded: boolean
  poolPointsFlowUrl: string
  rerunEarningsSimulator: boolean
  selectedDocuments: IDocumentsRowData[]
  setExportToExcel: Dispatch<SetStateAction<boolean>>
  setIsBulkSelectActive: Dispatch<SetStateAction<boolean>>
  setRerunEarningsSimulator: Dispatch<SetStateAction<boolean>>
  setSelectedDocuments: Dispatch<SetStateAction<IDocumentsRowData[]>>
  setVessel: Dispatch<SetStateAction<GetVesselRes | null>>
  vessel: GetVesselRes | null
}

export const VesselPageContext = createContext<VesselPageState | null>(
  null,
) as Context<VesselPageState>

type VesselPageProviderProps = {
  vessel: GetVesselRes | null
}

export default function VesselPageProvider({
  vessel: oldVessel,
  ...props
}: PropsWithChildren<VesselPageProviderProps>) {
  const [vessel, setVessel] = useState<GetVesselRes | null>(null)
  const [rerunEarningsSimulator, setRerunEarningsSimulator] =
    useState<boolean>(false)
  const [isBulkSelectActive, setIsBulkSelectActive] = useState<boolean>(false)
  const [selectedDocuments, setSelectedDocuments] = useState<
    IDocumentsRowData[]
  >([])
  const [exportToExcel, setExportToExcel] = useState<boolean>(false)

  const { search, pathname } = useLocation()
  const poolPointsFlowUrl = useMemo(() => {
    const queryPrefix = search.length > 0 ? '&' : '?'

    return `${pathname}${search}${queryPrefix}flow=pool-points`
  }, [pathname, search])

  const isVesselOnboarded = useMemo(
    () => vessel?.status === VesselStatus.ONBOARDED,
    [vessel?.status],
  )
  const isVesselArchived = useMemo(
    () => vessel?.status === VesselStatus.ARCHIVED,
    [vessel?.status],
  )

  useEffect(() => {
    if (!oldVessel) {
      console.log(
        '[VesselPageProvider]: Something went wrong. No vessel data found.',
      )

      return
    }

    setVessel(oldVessel)
  }, [oldVessel])

  return (
    <VesselPageContext.Provider
      value={{
        vessel,
        setVessel,
        rerunEarningsSimulator,
        setRerunEarningsSimulator,
        isBulkSelectActive,
        setIsBulkSelectActive,
        selectedDocuments,
        setSelectedDocuments,
        isVesselOnboarded,
        isVesselArchived,
        poolPointsFlowUrl,
        exportToExcel,
        setExportToExcel,
      }}
      {...props}
    />
  )
}

export const useVesselPageState = createContextStateHook(
  VesselPageContext,
  'VesselPageContext',
)
