import { useParams } from 'react-router-dom'
import { useGetTabList } from '~hooks/queries/vessels/data/use-get-tab-list'

export function useVesselLinks() {
  const { vesselId } = useParams()
  const { data: tabPermissions } = useGetTabList({ vesselId })
  const tabs = tabPermissions?.map((t) => t.id)

  const possibleVesselLinks = [
    ...(tabs?.includes('vesseldashboard')
      ? [
          {
            label: 'Vessel Dashboard',
            link: `vessels/${vesselId}/vessel-dashboard`,
          },
        ]
      : []),
    ...(tabs?.includes('poolpartnerdashboard')
      ? [
          {
            label: 'Vessel Dashboard',
            link: `vessels/${vesselId}/partner-dashboard`,
          },
        ]
      : []),
    ...(tabs?.includes('opsdashboard')
      ? [
          {
            label: 'Ops Dashboard',
            link: `vessels/${vesselId}/ops-dashboard`,
          },
        ]
      : []),
    ...(tabs?.includes('questionnaire')
      ? [
          {
            label: 'Questionnaire',
            link: `vessels/${vesselId}/questionnaire`,
          },
        ]
      : []),
    ...(tabs?.includes('basicvesseldata')
      ? [
          {
            label: 'Basic Vessel Data',
            link: `vessels/${vesselId}/basic-vessel-data`,
          },
        ]
      : []),
    ...(tabs?.includes('portessentials')
      ? [
          {
            label: 'Port Essentials',
            link: `vessels/${vesselId}/port-essentials`,
          },
        ]
      : []),
    ...(tabs?.includes('restrictions')
      ? [
          {
            label: 'Restrictions',
            link: `vessels/${vesselId}/pool-restrictions`,
          },
        ]
      : []),
    ...(tabs?.includes('poolpoints')
      ? [
          {
            label: 'Pool Points',
            link: `vessels/${vesselId}/pool-points`,
          },
        ]
      : []),
    ...(tabs?.includes('vetting')
      ? [
          {
            label: 'Vetting',
            link: `vessels/${vesselId}/vetting`,
          },
        ]
      : []),
    ...(tabs?.includes('certificates')
      ? [
          {
            label: 'Documents',
            link: `vessels/${vesselId}/documents`,
          },
        ]
      : []),
    ...(tabs?.includes('voyages')
      ? [
          {
            label: 'Voyages',
            link: `vessels/${vesselId}/voyages`,
          },
        ]
      : []),
  ]

  return possibleVesselLinks
}
