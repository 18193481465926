import { Alert } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { ReactNode } from 'react'
import {
  useLocation,
  useMatches,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { ExtendedRouteObject } from 'src/routes/routes'
import { Scope } from '~api/user/roles/scope-types'
import LoadComponent from '~components/molecules/load-component/load-component'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { useGetPools } from '~hooks/queries/pools/use-get-pools'
import Unauthorized from '~pages/auth/unauthorized-page'

export function PageGuard({ children }: { children?: ReactNode }) {
  const { data, isLoading, isError } = useGetMe()
  const matches = useMatches()
  useReplacePoolParam()

  if (isLoading) {
    return <LoadComponent />
  }
  if (isError || !data)
    return (
      <Alert
        variant="error"
        text="Oops, there was an error getting your user. Please reload the page or try again later."
      />
    )

  const restrictedRoutes = matches.filter(
    // @ts-ignore
    (match) => match.handle?.restrictedTo?.length > 0,
  )

  const hasAccess = restrictedRoutes.some((route) => {
    return matchScopes(route as ExtendedRouteObject, data.scope)
  })

  if (restrictedRoutes.length > 0 && !hasAccess)
    return (
      <Box sx={{ height: '70vh', display: 'flex' }}>
        <Unauthorized />
      </Box>
    )

  return <>{children}</>
}

function matchScopes(routeMatch: ExtendedRouteObject, scopes: Scope) {
  return scopes.some((scope: string) =>
    routeMatch.handle?.restrictedTo?.includes(scope),
  )
}

function useReplacePoolParam() {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const { data: pools } = useGetPools()

  const poolMatch = pools?.find((pool) => pool.id.toString() === params.pool)
  if (!poolMatch) return

  let pathPattern = location.pathname
  for (const key in params) {
    // @ts-ignore
    pathPattern = pathPattern.replace(params[key], `:${key}`)
  }

  // Replacing the parameter in the path pattern
  const newPath = pathPattern.replace(':pool', poolMatch.name.toLowerCase())
  navigate(newPath, { replace: true })
}
