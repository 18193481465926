import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Outlet, RouteObject } from 'react-router-dom'
import { Scope } from '~api/user/roles/scope-types'
import { GuidedTour } from '~components/guided-tour/guided-tour'
import LoadComponent from '~components/molecules/load-component/load-component'
import { PageGuard } from '~components/page-guard'
import { TermsOfUseModal } from '~components/terms-of-use-modal'
import { WelcomePopup } from '~components/welcome-popup/welcome-popup'
import { ActivePoolProvider } from '~context/active-pool-context'
import { VtRoutesWrapper } from '~routing/components/vt-routes-wrapper'
import { Auth0ProviderWithConfig } from '../components/auth0-provider-with-config'
import { AuthenticationGuard } from '../components/authentication-guard'
import NavigationAndFooter from '../components/navigation-and-footer/navigation-and-footer'
import ErrorPage from '../pages/error-page'
import { openPages } from './open-pages'
import { routesBehindLogin } from './routes-behind-login/routes-behind-login'
import {
  vtOpenPages,
  vtRoutesBehindLogin,
} from './routes-behind-login/vt-routing/vt-routes'

interface RouteHandle {
  restrictedTo?: Scope
  withPools?: boolean
  disableLeftNavigation?: boolean
}

export type ExtendedRouteObject = RouteObject & {
  handle: RouteHandle
}

export const pagesBehindLogin = [
  {
    element: (
      <AuthenticationGuard>
        <PageGuard>
          <Outlet />
        </PageGuard>
      </AuthenticationGuard>
    ),
    children: [...routesBehindLogin],
  },
]

export const vtPagesBehindLogin = [
  {
    element: (
      <AuthenticationGuard>
        <PageGuard>
          <Outlet />
        </PageGuard>
      </AuthenticationGuard>
    ),
    children: [...vtRoutesBehindLogin],
  },
]

export const routeWrapper: RouteObject[] = [
  {
    element: (
      <Auth0ProviderWithConfig>
        <HelmetProvider>
          <ActivePoolProvider>
            <GuidedTour />
            <TermsOfUseModal />
            <WelcomePopup />
            <NavigationAndFooter>
              <Suspense fallback={<LoadComponent />}>
                <Outlet />
              </Suspense>
            </NavigationAndFooter>
          </ActivePoolProvider>
        </HelmetProvider>
      </Auth0ProviderWithConfig>
    ),
    errorElement: <ErrorPage />,
    children: [...openPages, ...pagesBehindLogin],
  },
  {
    element: (
      <Auth0ProviderWithConfig>
        <GuidedTour />
        <TermsOfUseModal />
        <WelcomePopup />
        <VtRoutesWrapper />
      </Auth0ProviderWithConfig>
    ),
    children: [...vtPagesBehindLogin, ...vtOpenPages],
    errorElement: <ErrorPage />,
  },
]
