import { Box } from '@mui/material'
import { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { GetVesselRes } from '~hooks/queries/vessels/use-get-vessel'
import { AnimatedAlert } from '~components/molecules/animated-alert/animated-alert'
import AnimatedAlertProvider from '~components/molecules/animated-alert/animated-alert-provider'
import AutoCompleteInputProvider, {
  useAutoCompleteInputState,
} from '~components/molecules/auto-complete-input/auto-complete-input-provider'
import { NotificationsBanner } from '~components/notifications/notifications-banner'
import { EarningsSimulator } from '~components/organisms/modals/earnings-simulator/earnings-simulator'
import { VesselHeader } from '~pages/pages-behind-login/vessel-page/vessel-header/vessel-header'
import {
  getVesselPageSubHeaderData,
  VESSEL_PAGE_CONFIG,
  VESSEL_PAGE_HEADER_DATA,
} from '~pages/pages-behind-login/vessel-page/vessel-page-data'
import { StyledVesselPageWrapper } from '~pages/pages-behind-login/vessel-page/vessel-page.styles'
import { getActiveRoute } from '~utils/get-active-route'
import { gtm } from '~utils/gtm'
import { replaceStringInPathname } from '../utils'
import { AutoCompleteAndFavorites } from './sub-components/auto-complete-and-favorites'
import { SubHeaderText } from './sub-components/sub-header-text'

type VesselPageContentProps = {
  data: GetVesselRes
}

export const VesselPageContent = ({ data }: VesselPageContentProps) => (
  <AutoCompleteInputProvider>
    <AnimatedAlertProvider>
      <VesselPageContentInner vessel={data} />
    </AnimatedAlertProvider>
  </AutoCompleteInputProvider>
)

type VesselPageContentInnerProps = {
  vessel: GetVesselRes
}

const VesselPageContentInner = ({ vessel }: VesselPageContentInnerProps) => {
  const routeParams = useParams()
  const autoCompleteState = useAutoCompleteInputState()

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [, activeRoute] = getActiveRoute(pathname, routeParams)

  const subHeaderData = activeRoute
    ? getVesselPageSubHeaderData()[activeRoute]
    : undefined

  const pageConfig = activeRoute && {
    // @ts-ignore
    header: VESSEL_PAGE_HEADER_DATA[activeRoute],
    subHeader: subHeaderData,
    // @ts-ignore
    page: VESSEL_PAGE_CONFIG[activeRoute],
  }
  const PageProvider = pageConfig?.page?.PageProvider || Fragment

  useEffect(() => {
    const activeResult = autoCompleteState.activeResult

    if (!activeResult) return

    const resultVesselName = activeResult[2]?.value
    const resultVesselId = activeResult[0]?.value

    if (!resultVesselName && !resultVesselId) return

    gtm.pushEvent('internal_click', {
      click_type: 'vessel_searched',
      click_subtype: resultVesselName || resultVesselId,
    })
    const { vesselId } = routeParams
    const newPathname =
      vesselId && resultVesselId
        ? replaceStringInPathname(vesselId, resultVesselId)
        : undefined
    if (newPathname) {
      return navigate(newPathname, {
        replace: true,
      })
    }
  }, [autoCompleteState.activeResult])

  const { subHeader } = pageConfig || {}
  const { header, customComponent } = subHeader || {}

  return (
    <PageProvider>
      <Helmet>
        <title>{`${vessel.vesselName} | VesselTruth`}</title>
      </Helmet>

      <StyledVesselPageWrapper>
        <VesselHeader />
        <NotificationsBanner />
        <Box
          sx={{
            position: 'relative',
            padding: { xs: '0 56px', xxxxl: '0 152px' },
          }}
        >
          {subHeader && (
            <Box>
              <AutoCompleteAndFavorites />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 3,
                }}
              >
                <SubHeaderText
                  subHeaderText={header?.copy}
                  vesselName={vessel?.vesselName}
                />
                {header?.componentFn?.()}
                {customComponent?.()}
              </Box>
            </Box>
          )}
        </Box>
        <Outlet />
      </StyledVesselPageWrapper>

      <EarningsSimulator />
      <AnimatedAlert />
    </PageProvider>
  )
}
