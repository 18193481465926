import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useIsActiveUser } from '~hooks/permissions/use-is-active-user'
import { useConstructLinkWithPool } from '~hooks/use-construct-link-with-pool'

export interface MenuItemI {
  link: string
  label: string
}

export function usePrimaryFinancialNavLinks() {
  const { isActiveUser } = useIsActiveUser()
  const isTechnicalManager = useHasScope(ExactScopeName.technicalManager)
  const isInternalUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isPoolPartner = useHasScope(ExactScopeName.poolPartner)

  const technicalScopePages: MenuItemI[] = [
    {
      label: 'technical',
      link: '/panel/financials/technical-management',
    },
  ]

  const pagesForActiveUsers: MenuItemI[] = [
    {
      label: 'forecast',
      link: useConstructLinkWithPool('/panel/financials/forecast/:pool'),
    },
    {
      label: 'reports',
      link: useConstructLinkWithPool('/panel/financials/reports/:pool'),
    },
  ]

  const nonTechnicalScopePages: MenuItemI[] = [
    ...(!isActiveUser ? [] : pagesForActiveUsers),
    {
      label: 'earnings',
      link: useConstructLinkWithPool('/panel/financials/earnings/:pool'),
    },
  ]

  nonTechnicalScopePages.sort((a: MenuItemI, b: MenuItemI) => {
    const order = ['forecast', 'earnings', 'reports']
    return order.indexOf(a.label) - order.indexOf(b.label)
  })

  if (isTechnicalManager) {
    return technicalScopePages
  }

  if (isPoolPartner || isInternalUser) {
    return nonTechnicalScopePages
  }

  return []
}
