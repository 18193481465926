export enum SORT_BY_FILTER {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  NAME = 'name',
}

export interface Filters {
  opsCircles: string[] | null
  poolPartnerNames: string[] | null
  pools: string[] | null
  sortBy: SORT_BY_FILTER | null
  status: StatusFilterOptionValue
}

export const statusFilterOptionValues = [
  '',
  'onboarded',
  'onboarding',
  'archived',
  'incoming',
  'commercial-management',
] as const
export type StatusFilterOptionValue = (typeof statusFilterOptionValues)[number]

export enum FilterParamNameEnum {
  favorites = 'favoritesEnabled',
  poolPartnerName = 'poolPartnerName',
  opsCircle = 'opsCircle',
  pool = 'pool',
  sortBy = 'sortBy',
  status = 'status',
  vesselName = 'vesselName',
  harryPotterTheme = 'harryPotterTheme',
}
