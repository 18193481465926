import { PageNotFound } from '~pages/open-pages/page-not-found'
import ExposurePage from '~pages/pages-behind-login/position-list/exposure-page/exposure-page'
import PositionList from '~pages/pages-behind-login/position-list/position-list'
import PositionListPage from '~pages/pages-behind-login/position-list/position-list-page/position-list-page'
import { PageRoute } from '~routing/constants/page-route'
import { AUTH_ROUTES, UNAUTH_ROUTES } from '~routing/constants/routes'
import { getRoutes } from '~routing/utils'

const vtRoutesUnverified = [...getRoutes([PageRoute.VERIFY_EMAIL])]

export const vtRoutesBehindLogin = [
  {
    path: '*',
    element: <PageNotFound />,
  },
  ...getRoutes(AUTH_ROUTES),
  {
    path: PageRoute.POSITION_LIST,
    element: <PositionList />,
    children: [
      {
        index: true,
        element: <PositionListPage />,
        path: PageRoute.POSITION_LIST,
      },
      {
        path: PageRoute.EXPOSURE,
        element: <ExposurePage />,
      },
    ],
  },
  ...vtRoutesUnverified,
]

export const vtOpenPages = [...getRoutes(UNAUTH_ROUTES)]
