import { useAuth0 } from '@auth0/auth0-react'
import { MaerskIcon, PageWrapper } from '@maersktankersdigital/web-components'
import { Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import LoginButton from './components/login-button'
import LogoutButton from './components/logout-button'

export function TopNavigationOpenPages() {
  const { isAuthenticated } = useAuth0()
  return (
    <PageWrapper>
      <Box
        component="nav"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          pt: 4,
        }}
      >
        <Link component={RouterLink} to="">
          <MaerskIcon width="auto" height="24px" />
        </Link>
        <Box
          sx={{
            mt: { xs: 3, md: 0 },
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
        </Box>
      </Box>
    </PageWrapper>
  )
}
