import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

export interface MuiCheckboxData {
  defaultProps?: ComponentsProps['MuiCheckbox']
  styleOverrides?: ComponentsOverrides['MuiCheckbox']
  variants?: ComponentsVariants['MuiCheckbox']
}

const CheckSVG = ({ isChecked }: { isChecked?: boolean }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="26"
      height="26"
      rx="2"
      fill={isChecked ? COLORS.primary.maerskBlue : 'transparent'}
      stroke={isChecked ? COLORS.primary.maerskBlue : COLORS.greys.mid}
      strokeWidth={2}
    />
    <path
      d="M24.5573 9.69865L16.4216 22.065C16.1749 22.4399 15.7564 22.6655 15.3077 22.6655H13.3127C12.917 22.6655 12.5417 22.4898 12.2884 22.1858L7.48828 16.4257L8.51257 15.5721L13.3127 21.3322H15.3077L23.4435 8.96582L24.5573 9.69865Z"
      fill={isChecked ? 'white' : 'transparent'}
    />
  </svg>
)

const createSvgContent = (
  fillColor: string,
  strokeColor: string,
  checkColor: string,
) =>
  `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3' y='3' width='26' height='26' rx='2' fill='${encodeURIComponent(fillColor)}' stroke='${encodeURIComponent(strokeColor)}' stroke-width='2'/%3E%3Cpath d='M24.5573 9.69865L16.4216 22.065C16.1749 22.4399 15.7564 22.6655 15.3077 22.6655H13.3127C12.917 22.6655 12.5417 22.4898 12.2884 22.1858L7.48828 16.4257L8.51257 15.5721L13.3127 21.3322H15.3077L23.4435 8.96582L24.5573 9.69865Z' fill='${encodeURIComponent(checkColor)}'/%3E%3C/svg%3E")`

export const checkboxHoverStyle = {
  borderRadius: 'initial',
  content: createSvgContent(
    COLORS.primary.white,
    COLORS.primary.maerskBlue,
    COLORS.primary.maerskBlue,
  ),
}

const checkboxDisabledStyle = {
  ...checkboxHoverStyle,
  content: createSvgContent(
    COLORS.greys.light,
    COLORS.greys.light,
    COLORS.primary.white,
  ),
}

export const MuiCheckbox: MuiCheckboxData = {
  defaultProps: {
    disableRipple: true,
    checkedIcon: <CheckSVG isChecked />,
    icon: <CheckSVG />,
  },
  styleOverrides: {
    root: {
      paddingLeft: '10px',
      '&.Mui-focusVisible': {
        color: COLORS.secondary.lightBlue,
        backgroundColor: COLORS.secondary.blueTint,
      },
      '&.Mui-disabled': { ...checkboxDisabledStyle },
      '&:not(.Mui-checked):hover:not(.Mui-disabled)': { ...checkboxHoverStyle },
    },
  },
}
