import { TextButton, theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { StyledAutoCompleteInputWrapper } from '~components/molecules/auto-complete-input/auto-complete-input.styles'

export const FilterControlsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding-top: 20px;
  align-items: center;
`

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${theme.SPACINGS[3]};
`

export const AutoCompleteWrapper = styled(StyledAutoCompleteInputWrapper)`
  margin-top: 20px;
  width: 250px;
  margin-left: auto;

  & input {
    background-color: ${COLORS.greys.ultraLight};
    border: none;
    box-shadow: inset 9px 9px 13px rgb(0 0 0 / 3%);
  }
`

export const LoadingPlaceholder = styled.div`
  height: 72px;
`

export const FavoritesTextButton = styled(TextButton)<{ $filled?: boolean }>`
  &:hover span svg path {
    fill: ${({ $filled }) =>
      $filled ? COLORS.red.mid : 'transparent'} !important;
  }
`
