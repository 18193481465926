import TagManager from 'react-gtm-module'
import { GetVesselRes } from '~hooks/queries/vessels/use-get-vessel'
import { GetPoolsResItem } from '~hooks/queries/pools/use-get-pools'

const isGtmInitialized = () => !!(window as any).dataLayer

const pushEvent = (name: string, args?: Record<string, any>) => {
  const intervalId = setInterval(() => {
    if (isGtmInitialized()) {
      clearInterval(intervalId)
      TagManager.dataLayer({
        dataLayer: {
          event: name,
          ...args,
        },
      })
    } else {
      console.log('gtm not initialized yet, postponing')
    }
  }, 200)
}

const virtualPageView = (
  title: string,
  args?: Record<string, any>,
  vesselData?: GetVesselRes | null,
  pools?: GetPoolsResItem[],
) => {
  pushEvent('page_view', {
    virtual_page_url: window.location.href,
    virtual_page_title: title,
    ...args,
    vessel_data: {
      vessel_id: vesselData?._id,
      vessel_name: vesselData?.vesselName,
      ...args?.vessel_data,
    },
    pool_data: {
      pool_id:
        !vesselData?.pool || !pools
          ? undefined
          : pools.find((pool) => pool.name === vesselData?.pool)?.id,
      pool_name: vesselData?.pool,
      pool_partner: vesselData?.poolPartner,
      ...args?.pool_data,
    },
  })
}

export const gtm = {
  pushEvent,
  virtualPageView,
}
