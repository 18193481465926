import { CommentsIcon } from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { Modal } from '~components/molecules/modal/modal'
import { COLORS } from '~theme/colors'
import { typography } from '~theme/global'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'

export const useLinks = () => {
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)

  return isMtUser
    ? [
        {
          area: 'General',
          link: 'msteams://teams.microsoft.com/l/channel/19%3AfP69f__K6f3edstPV9ZKUAXsu1fhtUh76dP1_5neh_Q1%40thread.tacv2/General?groupId=abcd8e2a-dfd4-4c92-ba7f-fd4947897512&tenantId=b55d03e2-fd95-480a-91ca-9cd58f42f07c',
        },
        {
          area: 'Chartering',
          link: 'msteams://teams.microsoft.com/l/channel/19%3A28528eb0b2aa4043a3f5a27a4b2cb9dd%40thread.tacv2/Chartering?groupId=abcd8e2a-dfd4-4c92-ba7f-fd4947897512&tenantId=b55d03e2-fd95-480a-91ca-9cd58f42f07c',
        },
        {
          area: 'Operations',
          link: 'msteams://teams.microsoft.com/l/channel/19%3A764382b03e8744a2807923f4df85127a%40thread.tacv2/Operations?groupId=abcd8e2a-dfd4-4c92-ba7f-fd4947897512&tenantId=b55d03e2-fd95-480a-91ca-9cd58f42f07c',
        },
        {
          area: 'Pool Excellence',
          link: 'msteams://teams.microsoft.com/l/channel/19%3A3a9e5ed290c84a8d898b4b8556e3fd7b%40thread.tacv2/Pool%20Excellence?groupId=abcd8e2a-dfd4-4c92-ba7f-fd4947897512&tenantId=b55d03e2-fd95-480a-91ca-9cd58f42f07c',
        },
      ]
    : [
        {
          area: 'Contact',
          link: 'mailto:digital-support@maersktankers.com',
        },
      ]
}

export const useTitleAndSubTitle = () => {
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)
  const title = isMtUser
    ? 'Which business area is affected?'
    : 'Report an issue'
  const subtitle = isMtUser
    ? 'By clicking the area you will be directed to a specific Teams Channel'
    : 'Click on the button below to send an email to the support team.'

  return { title, subtitle }
}

export const TeamsButtons = ({ onClick }: { onClick?: () => void }) => {
  const links = useLinks()

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, mt: 5 }}>
      {links.map((link) => (
        <Box
          component="a"
          key={link.area}
          href={link.link}
          onClick={() => onClick && onClick()}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: 'none',
            p: 3,
            borderRadius: '100px',
            border: `solid 1px ${COLORS.primary.maerskBlue}`,
            boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.08)',
            ...typography.paragraph2,
            textTransform: 'uppercase',
            '&:hover': {
              backgroundColor: COLORS.primary.maerskBlue,
              color: COLORS.primary.white,
            },
          }}
        >
          {link.area}
        </Box>
      ))}
    </Box>
  )
}

export function ReportIssue() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { title, subtitle } = useTitleAndSubTitle()

  return (
    <>
      <Box
        component="button"
        onClick={() => setIsModalOpen(true)}
        sx={{
          backgroundColor: COLORS.secondary.midBlue,
          top: 228,
          right: 12,
          zIndex: 9999,
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
          p: 2,
          borderRadius: '100px',
          cursor: 'pointer',
          border: 'none',
          ...typography.menu,
          color: COLORS.primary.white,
          '&:hover': {
            '&::after': {
              content: '"Report an issue"',
              display: 'block',
            },
          },
        }}
      >
        <CommentsIcon fontSize="20px" />
      </Box>

      <Modal
        open={isModalOpen}
        title={title}
        onClose={() => setIsModalOpen(false)}
      >
        <Box sx={{ mt: -2 }}>
          <Typography variant="paragraph2">{subtitle}</Typography>
          <TeamsButtons onClick={() => setIsModalOpen(false)} />
        </Box>
      </Modal>
    </>
  )
}
