import { Box, Typography } from '@mui/material'
import { Step } from 'react-joyride'
import { Link } from 'react-router-dom'
import { lastStep } from './common-steps'

export function constructDashboardTour({
  dashboardLink,
  variant,
}: {
  variant: 'default' | 'xs'
  dashboardLink: string
}): Step[] {
  const defaultDashboardTour: Step[] = [
    {
      content: (
        <>
          <Typography variant="label" sx={{ mb: 3 }} component="p">
            Let's begin the tour of the dashboard!
          </Typography>
          <Typography variant="paragraph2">
            If you decide to skip the tour, you can find it again on{' '}
            <Link to="/panel/help">help</Link> page.
          </Typography>
        </>
      ),
      target: 'body',
      placement: 'center',
    },
    {
      target: '.tour-step',
      content: (
        <Typography variant="paragraph2">
          Emissions and Documents pages were moved to the top menu
        </Typography>
      ),
    },
    {
      target: '.tour-step-key-figures',
      content: (
        <>
          <Typography sx={{ mb: 3 }} variant="label" component="p">
            This is Key Figures graph
          </Typography>
          <Typography variant="paragraph2">
            Here you can see the earnings for 2 months back, the current month
            as well as the forecast.
          </Typography>
        </>
      ),
    },
    {
      target: '.tour-step-vessels-table',
      placement: 'right',
      content: (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            justifyContent: 'center',
          }}
        >
          <img
            src="https://strmtnoeutpoolpartner01.blob.core.windows.net/public/expandable-dashboard-table.gif"
            title="expandable table row"
          />
          <Typography sx={{ mt: 3 }} variant="label">
            This is Vessels Table
          </Typography>
          <Typography variant="paragraph2">
            If you click the plus icon, you can see the voyage. Try it!
          </Typography>
        </Box>
      ),
    },
    {
      target: '.tour-step-2',
      content: (
        <>
          <Typography sx={{ mb: 3 }} variant="label" component="p">
            News Feed
          </Typography>
          <Typography variant="paragraph2">
            Here you can find quick updates about the recent happenings.
          </Typography>
        </>
      ),
      placementBeacon: 'top',
      placement: 'left',
    },
    {
      target: '.tour-step-market-comments',
      content: (
        <>
          <Typography sx={{ mb: 3 }} variant="label" component="p">
            Market Comments
          </Typography>
          <Typography variant="paragraph2">
            You can subscribe to receive the comments on your email too. This is
            done on the profile page. Let's go there now.
          </Typography>
        </>
      ),
      data: {
        next: '/profile',
        previous: dashboardLink,
      },
      placementBeacon: 'top',
      placement: 'right',
    },
    {
      target: '.tour-step-subscription-settings',
      content: (
        <>
          <Typography sx={{ mb: 3 }} variant="label" component="p">
            Subscription settings
          </Typography>
          <Typography variant="paragraph2">
            Here you can subscribe to receive the market comments on your email.
          </Typography>
        </>
      ),
      data: {
        next: dashboardLink,
        previous: dashboardLink,
      },
    },
    {
      ...lastStep,
      data: {
        previous: '/profile',
      },
    },
  ]

  if (variant === 'default') return defaultDashboardTour

  return defaultDashboardTour.map((step) => ({
    ...step,
    target: 'body',
    placement: 'center',
  }))
}
