import { useQuery } from '@tanstack/react-query'

import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export function useNotifications() {
  const fetchRequest = useFetch<AlertNotification[]>()
  return useQuery({
    queryKey: ['/notificationbanner'],
    queryFn: () => fetchRequest(`${apiBase()}/notification/banners`),
    staleTime: tenMinutes,
  })
}

export type NotificationVariantAPI =
  | 'information'
  | 'critical'
  | 'warning'
  | 'success'

export interface AlertNotification {
  id: string | number
  type: NotificationVariantAPI
  startDate: string
  endDate: string | null
  headline: string
  text: string
  canDismiss: boolean
  isActive: boolean
  pools: string[]
  pages: string[]
  creationDate: string
  author: string
  linkTitle: string
  linkUrl: string
}
