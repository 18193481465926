const retainedPostLogout = {
  showHorizontalScrollMessage: 'show_horizontal_scroll_message',
  allowedCookies: 'allowed_cookies',
  useMockData: 'use_mock_data',
}

export const localStorageItemKeys = {
  notificationBanners: 'HideNotificationBanners',
  welcomePopupClosed: 'welcome_popup_closed',
  emailSubscriptions: 'email_subscriptions',
  triggeredGuidedTour: 'triggered_guided_tour',
  guidedTourStepIndex: 'guided_tour_step_index',
  retainedPostLogout,
}
