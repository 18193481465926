import { useBreakpoint } from '@maersktankersdigital/web-components'
import { Step } from 'react-joyride'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { useConstructLinkWithPool } from '~hooks/use-construct-link-with-pool'
import { constructDashboardTour } from './dashboard-tour'

export interface GuidedTourListItem {
  id: string
  name: string
  status: GuidedTourStatus
  tourStartLink: string
  content: Step[]
  tourPaths: string[]
  startTourPath: string
}

export type GuidedTourStatus = 'Not Seen' | 'Started' | 'Completed'

export function useGuidedToursList(): GuidedTourListItem[] {
  const { data, isLoading, isError } = useGetMe()

  const { breakpoint } = useBreakpoint()
  const isSmallScreen =
    breakpoint === 'sm' || breakpoint === 'xs' || breakpoint === 'md'
  const dashboardLink = useConstructLinkWithPool('/panel/dashboard/:pool')

  if (isLoading || isError) return []

  const guidedTours = data?.settings?.guidedTours || []

  function getTourStatus(tourId: string): GuidedTourStatus {
    return guidedTours?.find((tour) => tour.id === tourId)?.status ?? 'Not Seen'
  }

  return [
    {
      id: '1',
      name: 'Dashboard',
      status: getTourStatus('1'),
      tourStartLink: dashboardLink,
      content: constructDashboardTour({
        variant: isSmallScreen ? 'xs' : 'default',
        dashboardLink,
      }),

      tourPaths: ['/panel/dashboard/:pool', '/profile'],
      startTourPath: '/panel/dashboard/:pool',
    },
    {
      id: '2',
      name: 'Lorem Ipsum',
      status: getTourStatus('2'),
      tourStartLink: '/panel/help',
      content: [],
      tourPaths: ['/panel/help'],
      startTourPath: '/panel/help',
    },
    {
      id: '3',
      name: 'Dolor sit amet',
      status: getTourStatus('4'),
      tourStartLink: '/panel/help',
      content: [],
      tourPaths: ['/panel/help'],
      startTourPath: '/panel/help',
    },
  ]
}
