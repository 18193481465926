import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { ParagraphSize } from '~components/atoms/typography/paragraph/paragraph.styles'
import { usePoolPointsPageState } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-page-provider'
import { getMaxPossiblePoints } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-result/pool-points-result.utils'

import { Box } from '@mui/material'
import { StyledPoolPointsTableResult } from './pool-points-result.styles'

type PoolPointsTableResultProps = {
  color?: string
  fontSize?: ParagraphSize
  resultFontSize?: ParagraphSize
  showPointsOnly?: boolean
}

export const PoolPointsResult = ({
  color,
  resultFontSize = 'large',
  fontSize = 'medium',
  showPointsOnly,
}: PoolPointsTableResultProps) => {
  const state = usePoolPointsPageState()
  const poolPoints = state.poolPoints?.[0]

  const maxPossiblePoints = getMaxPossiblePoints(poolPoints)

  return (
    <StyledPoolPointsTableResult>
      {poolPoints && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {!showPointsOnly && (
            <Paragraph
              weight="bold"
              size={resultFontSize}
              color={color || 'inherit'}
            >
              In total
            </Paragraph>
          )}
          <Paragraph color={color || 'inherit'}>
            <Paragraph weight="bold" size={resultFontSize} as="span">
              {(
                poolPoints?.totalPoolPointsPlusCorrections?.result ??
                poolPoints?.totalPoolPoints?.result ??
                0
              ).toString()}
            </Paragraph>

            <Paragraph size={fontSize} as="span">
              {` out of ${maxPossiblePoints} possible points`}
            </Paragraph>
          </Paragraph>
        </Box>
      )}
    </StyledPoolPointsTableResult>
  )
}
