import { Alert } from '@maersktankersdigital/web-components'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import LoadComponent from '~components/molecules/load-component/load-component'
import { VesselPageContent } from '~pages/pages-behind-login/vessel-page/vessel-page-content/vessel-page-content'
import { StyledVesselPage } from '~pages/pages-behind-login/vessel-page/vessel-page.styles'
import { useGetVessel } from '~hooks/queries/vessels/use-get-vessel'
import { SWRConfig } from 'swr'
import { ApiConfig } from '~api'
import { useStore } from '~context/store-provider'
import { DataWrapper } from '~routing/components/app'
import VesselPageProvider from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { useHasRole } from '~hooks/permissions/use-has-role'
import { useIsMtOpsUser } from '~hooks/permissions/use-is-mt-ops-user'
import { useHasScope, ExactScopeName } from '~hooks/permissions/use-has-scope'
import { useGetTabList } from '~hooks/queries/vessels/data/use-get-tab-list'
import { setTokenGetter } from '~utils/auth'
import { useAuth0 } from '@auth0/auth0-react'

const useVesselLandingRedirect = () => {
  const { vesselId } = useParams()
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    setTokenGetter(getAccessTokenSilently)
  }, [getAccessTokenSilently])

  const useConstructLinkToMainTabForUser = () => {
    const { data: tabPermissions } = useGetTabList({ vesselId })
    const tabs = tabPermissions?.map((t) => t.id)
    const isMtOps = useIsMtOpsUser()
    const isCrew = useHasRole({ entity: 'vessel', role: 'crew' })
    const isPoolPartner = useHasScope(ExactScopeName.poolPartner)
    if (!tabs) return

    const hasOpsDashboard = tabs?.includes('opsdashboard')
    const hasDocumentPage = tabs?.includes('certificates')
    const hasPartnerDashboard = tabs?.includes('poolpartnerdashboard')
    const hasVesselDashboard = tabs?.includes('vesseldashboard')

    if (isMtOps && hasOpsDashboard) return 'ops-dashboard'
    if (isCrew && hasDocumentPage) return 'documents'
    if (isPoolPartner && hasPartnerDashboard && !hasVesselDashboard)
      return 'partner-dashboard'

    if (!hasVesselDashboard) return 'questionnaire'

    return 'vessel-dashboard'
  }

  return useConstructLinkToMainTabForUser()
}

export default function VesselPage() {
  const navigate = useNavigate()
  const linkToMainTab = useVesselLandingRedirect()
  const { vesselId, ...rest } = useParams()
  const store = useStore()

  useEffect(() => {
    if (Object.values(rest).filter((r) => !!r).length > 0) return
    // The above checks for route paths after the vessel ID and avoids a redirect if someone is already on a subpage
    // This is because the redirect logic should only kick in when you are on the top level route
    if (!linkToMainTab) return
    navigate(`/vessels/${vesselId}/${linkToMainTab}`)
  }, [linkToMainTab, vesselId, rest])

  const {
    data,
    error: hasVesselError,
    isLoading: isVesselLoading,
  } = useGetVessel({ vesselId })

  if (isVesselLoading) return <LoadComponent />

  if (!data || hasVesselError) {
    return (
      <Alert
        variant="error"
        text="Oops! There was an error loading the vessel. Please reload the page or try again later."
      />
    )
  }

  if (!vesselId) {
    return (
      <Alert
        variant="error"
        text="Oops! It looks like no data exists for this vessel. Please try a different vessel or try again later."
      />
    )
  }

  return (
    <StyledVesselPage>
      <VesselPageProvider vessel={data || null}>
        <SWRConfig value={ApiConfig.swrDefaults}>
          <DataWrapper store={store}>
            <VesselPageContent data={data} />
          </DataWrapper>
        </SWRConfig>
      </VesselPageProvider>
    </StyledVesselPage>
  )
}
