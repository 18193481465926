import { PageWrapper, Typography } from '@maersktankersdigital/web-components'
import { useParams, useLocation } from 'react-router-dom'
import { getActiveRoute } from '~utils/get-active-route'
import {
  getVesselPageSubHeaderData,
  VESSEL_PAGE_CONFIG,
  VESSEL_PAGE_HEADER_DATA,
} from '~pages/pages-behind-login/vessel-page/vessel-page-data'
import { useGetVessel } from '~hooks/queries/vessels/use-get-vessel'
import { InfoLabel } from '~components/atoms/info-label/info-label'
import { DeliveryLabel } from '~pages/pages-behind-login/vessel-page/delivery-label/delivery-label'
import { RedeliveryLabel } from '~pages/pages-behind-login/vessel-page/redelivery-label/redelivery-label'
import { HeaderActions } from '~pages/pages-behind-login/vessel-page/vessel-header/header-actions/header-actions'
import { HeaderDetails } from '~pages/pages-behind-login/vessel-page/vessel-header/header-details/header-details'
import { useVesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { Z_INDEX } from '~types/z-index'

export enum TrackFieldValue {
  EARNINGS_SIMULATION = 'earnings-simulation',
  NORMAL = 'normal',
  COMMERCIAL_MANAGEMENT = 'commercial-management',
  ONBOARDING = 'onboarding',
}

import { Box } from '@mui/material'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'
import { COLORS } from '~theme/colors'
import { StyledHeadlineAndActionsContainer } from './vessel-header.styles'

export function VesselHeader() {
  const { topNavigationHeight } = useNavAndFooterConstants()
  const { vesselId } = useParams()
  const state = useVesselPageState()
  const routeParams = useParams()
  const { pathname } = useLocation()
  const [, activeRoute] = getActiveRoute(pathname, routeParams)

  const subHeaderData = activeRoute
    ? getVesselPageSubHeaderData()[activeRoute]
    : undefined

  const pageConfig = activeRoute && {
    // @ts-ignore
    header: VESSEL_PAGE_HEADER_DATA[activeRoute],
    subHeader: subHeaderData,
    // @ts-ignore
    page: VESSEL_PAGE_CONFIG[activeRoute],
  }

  const {
    data: vessel,
    isLoading: isLoadingVesselData,
    error: errorVesselData,
  } = useGetVessel({ vesselId })

  const isCommercialManagement =
    vessel?.status === TrackFieldValue.COMMERCIAL_MANAGEMENT

  return (
    <Box
      sx={{
        position: 'sticky',
        top: topNavigationHeight,
        left: 0,
        background: COLORS.greys.ultraLight,
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        zIndex: Z_INDEX.PageHeader,
      }}
    >
      {vessel && (
        <Box
          component={PageWrapper}
          sx={{
            py: 4,
            px: '52px !important',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: { xs: 2, xl: 7 },
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <StyledHeadlineAndActionsContainer>
                  <Typography variant="headline1">
                    {vessel.vesselName}
                  </Typography>
                  <HeaderActions vesselData={vessel} />
                </StyledHeadlineAndActionsContainer>
                {isCommercialManagement && (
                  <Typography variant="menu">Commercial Management</Typography>
                )}
              </Box>

              <HeaderDetails vessel={vessel} />
            </Box>
            {pageConfig?.header?.buttonComponentFn?.(state)}
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mt: 0, flexWrap: 'wrap' }}>
            <DeliveryLabel />
            <RedeliveryLabel />
            {state.isVesselArchived && (
              <InfoLabel text="archived" variant="VesselDashboard" />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
