import { FC } from 'react'

import { Box } from '~components/atoms/box'
import { Text } from '~components/atoms/text'

import { ClickPoint, CloseIcon } from '@maersktankersdigital/web-components'
import { styles } from './styles'

export const ModalToolbar: FC<{
  close?: () => void
  title?: string
}> = ({ title, close }) => {
  return (
    <div style={styles.toolbar}>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pl={4}
      >
        <Text text={title ?? ''} size="h5" color="white" headline />
        <Box flex={0} mr={2}>
          <ClickPoint
            ariaLabel="Close"
            onClick={() => {
              if (close) {
                close()
              }
            }}
            variant="dark"
          >
            <CloseIcon />
          </ClickPoint>
        </Box>
      </Box>
    </div>
  )
}
