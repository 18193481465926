import { useGetMe } from '../queries/me/use-get-me'

export enum ExactScopeName {
  accountManager = '*:*:accountmanager',
  charter = 'vessel:*:chartering',
  maerskTankersUser = '*:*:mtuser',
  mtopsVessel = 'vessel:*:mtops',
  poolPartner = 'Pool Partner',
  positionListAdmin = 'positionlist:admin',
  positionListUpdate = 'positionlist:update',
  superReader = '*:*:reader',
  superUser = '*:*:admin',
  technicalManager = 'Technical Manager',
}

export function useHasScope(scope: ExactScopeName) {
  const { data } = useGetMe()
  return data?.scope.some((item) => item === scope)
}
