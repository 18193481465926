import { Button } from '@maersktankersdigital/web-components'
import dot from 'dot-object'
import * as React from 'react'
import { FC, useState } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { mutate } from 'swr'

import { ApiClient, ApiRoutes } from '~api'
import { IVesselsQuestionnaireUpdatePayload } from '~api/vessels/questionnaire/update'
import { Box } from '~components/atoms/box'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { Group } from '~components/molecules/group/group'
import { Modal } from '~components/molecules/modal/legacy-modal'
import { IQuestionnaireListSection } from '~components/organisms/lists/vessel-questionnaires'
import {
  VesselQuestionnaireMissingFields,
  rebuildCurrentFieldsValuesObject,
} from '~components/organisms/modals/earnings-simulator/questionnaire-missing-fields'
import { VesselQuestionnaireModalContent } from '~components/organisms/modals/earnings-simulator/vessel-questionnaire-modal'
import { ModalTemplate } from '~components/templates/modal/modal-template'
import { useVesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { generatePathName } from '~utils/generate-path-name'

import { queryClient } from 'src/'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { useApi } from '../../../../hooks/use-api'

interface IEarningSimulator {
  close?: () => void
  currentSection: IQuestionnaireListSection
  currentSectionIndex: number
  flow: string
  flowName: string
  isLastStep: boolean
  setCurrentSectionIndex: React.Dispatch<React.SetStateAction<number>>
  vesselId: string
}

const ACTIONS = {
  NEXT: 'NEXT',
  PREV: 'PREV',
}

interface IFlowData {
  name: string
  sections: IQuestionnaireListSection[]
}

export const EarningsSimulator: FC = () => {
  const { vesselId } = useParams()
  const state = useVesselPageState()

  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0)

  const [searchParams, setSearchParams] = useSearchParams()
  const flow = searchParams.get('flow')

  const { data: flowData } = useApi<IFlowData>(
    vesselId && flow ? ApiRoutes.Vessels.flow : '',
    {
      vesselId,
      flowId: flow || undefined,
    },
  )

  if (!vesselId || !flow || !flowData) {
    return null
  }

  const sections = flowData?.sections?.map((section) => ({
    ...section,
    userRights: {
      canSee: true,
      canEdit: true,
      canApprove: false,
    },
    fields: section.fields.map((field) => ({
      ...field,
      missing: field.type === 'downl_upl' ? false : field.missing,
      canEdit: section.canEdit,
      userRights: {
        canSee: true,
        canEdit: true,
        canApprove: false,
      },
      allowComments: false,
      allowFiles: false,
    })),
  }))
  const currentSection = sections[currentSectionIndex]
  const isLastStep = flowData?.sections?.length === currentSectionIndex + 1

  return flowData?.sections?.length ? (
    <Modal
      handleAfterClose={() => {
        searchParams.delete('flow')

        setSearchParams(searchParams)
        state.setRerunEarningsSimulator(false)
      }}
      title={`${flowData?.name}, section ${currentSectionIndex + 1}/${
        flowData.sections.length
      }: ${currentSection.name}`}
      initialIsOpen
      content={
        <EarningsSimulatorContent
          currentSection={currentSection}
          currentSectionIndex={currentSectionIndex}
          setCurrentSectionIndex={setCurrentSectionIndex}
          vesselId={vesselId}
          isLastStep={isLastStep}
          flow={flow}
          flowName={flowData?.name || ''}
        />
      }
    />
  ) : null
}

const EarningsSimulatorContent: FC<IEarningSimulator> = ({
  close,
  vesselId,
  currentSectionIndex,
  currentSection,
  isLastStep,
  setCurrentSectionIndex,
  flow,
  flowName,
}) => {
  const { setStatus } = useAnimatedAlert()
  const [, setActionToPerform] = useState<string>(ACTIONS.NEXT)
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false)
  const [isPrevLoading, setIsPrevLoading] = useState<boolean>(false)
  const methods = useForm()

  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams(search)

  const dirtyFields = dot.dot(methods.formState.dirtyFields)

  const getFieldsToSave = (
    formValues: FieldValues,
  ): IVesselsQuestionnaireUpdatePayload['fields'] => {
    const fields = []
    for (const prop in formValues) {
      if (formValues.hasOwnProperty(prop)) {
        fields.push({
          id: prop.split('_')[0],
          [prop.split('_')[1]]: formValues[prop],
        })
      }
    }

    return fields
      .map((fieldToSave) => {
        return {
          ...fieldToSave,
          value:
            fieldToSave.value === '' && dirtyFields[`${fieldToSave.id}_value`]
              ? null
              : fieldToSave.value,
          comment:
            dirtyFields[`${fieldToSave.id}_comment`] ||
            fieldToSave.comment === null
              ? fieldToSave.comment
              : undefined,
        }
      })
      .filter((fieldToSave) => {
        return (
          (fieldToSave.value ||
            fieldToSave.value === null ||
            fieldToSave.comment === null ||
            fieldToSave.comment) &&
          (dirtyFields[`${fieldToSave.id}_value`] ||
            dirtyFields[`${fieldToSave.id}_comment`])
        )
      })
  }

  const onSave: SubmitHandler<FieldValues> = async (formValues) => {
    // using setActionToPerform here to get the most recent version of actionToPerform
    await setActionToPerform((action) => {
      const fieldsToSave = getFieldsToSave(formValues)
      const fieldsToSaveIds = fieldsToSave.map((field) => field.id)
      const shouldCompleteFlow = isLastStep && action === ACTIONS.NEXT

      if (fieldsToSave.length || shouldCompleteFlow) {
        const payload = {
          id: currentSection.id,
          fields: fieldsToSave,
          ...(shouldCompleteFlow && {
            'flow-completed': flow,
          }),
        }

        ApiClient.Vessels.Questionnaire.update(payload, {
          vesselId,
        })
          .then((response) => {
            queryClient.invalidateQueries({
              queryKey: [`/vessels/${vesselId}/data?tab=opsdashboard`],
            })

            if (response?.length) {
              methods.reset({ ...methods.getValues() })

              // @ts-ignore
              mutate(
                generatePathName(ApiRoutes.Vessels.flow, {
                  vesselId,
                  flowId: flow,
                }),
                (flowData: IFlowData) => ({
                  ...flowData,
                  sections: flowData.sections.map((flowDataSection) =>
                    flowDataSection.id === currentSection.id
                      ? {
                          ...flowDataSection,
                          fields: flowDataSection.fields.map((field) => {
                            if (fieldsToSaveIds.includes(field.id)) {
                              return {
                                ...field,
                                ...fieldsToSave.find(
                                  (fieldToSave) => fieldToSave.id === field.id,
                                ),
                              }
                            }

                            return field
                          }),
                        }
                      : flowDataSection,
                  ),
                }),
                false,
              )

              if (close && shouldCompleteFlow) {
                close()
                setStatus(
                  'Data has been successfully updated',
                  undefined,
                  'success',
                )
                searchParams.delete('flow')
                setSearchParams(searchParams)

                setCurrentSectionIndex(0)
              } else {
                setCurrentSectionIndex(
                  action === ACTIONS.NEXT
                    ? currentSectionIndex + 1
                    : currentSectionIndex - 1,
                )
                scrollToTheTop()
              }
            } else {
              setStatus('Saving form failed', undefined, 'error')
            }
          })
          .finally(() => {
            setIsNextLoading(false)
            setIsPrevLoading(false)
          })
      } else {
        setIsNextLoading(false)
        setIsPrevLoading(false)
        setCurrentSectionIndex(
          action === ACTIONS.NEXT
            ? currentSectionIndex + 1
            : currentSectionIndex - 1,
        )
        scrollToTheTop()
      }

      return action
    })
  }

  const onError: SubmitErrorHandler<FieldValues> = (err) => {
    setIsNextLoading(false)
    return console.log({ err })
  }

  const scrollContainer = document.querySelector('.earning-simulator-form')
  const scrollToTheTop = () => {
    scrollContainer?.scrollTo({ top: 0 })
  }

  const save = () => {
    methods.handleSubmit(onSave, onError)()
  }

  const prev = () => {
    setActionToPerform('PREV')
    setIsPrevLoading(true)
    save()
  }

  const next = () => {
    setActionToPerform('NEXT')
    setIsNextLoading(true)
    save()
  }

  const currentFieldsValues = methods.watch()
  rebuildCurrentFieldsValuesObject(currentFieldsValues)

  const missingFields = currentSection.fields.filter((field) => {
    const currentFieldValue = currentFieldsValues[field.id]

    const isCurrentFieldValueMissing =
      !currentFieldValue?.value ||
      currentFieldValue?.value === '' ||
      currentFieldValue?.value === false ||
      currentFieldValue?.value.length === 0

    const isFieldDependantAndActive =
      field.dependsOn &&
      field.dependsOn.value === currentFieldsValues[field.dependsOn.id]?.value

    return (
      field.missing &&
      isCurrentFieldValueMissing &&
      (!field.dependsOn || isFieldDependantAndActive)
    )
  })

  return (
    <FormProvider {...methods}>
      <ModalTemplate
        className="earning-simulator-form"
        content={
          <form onSubmit={save}>
            <VesselQuestionnaireModalContent section={currentSection} />
          </form>
        }
        sidebar={
          <Box mh={2.4} mt={5}>
            <Group spacing={2} flexDirection="row" alignItems="center">
              <Button
                isLoading={isPrevLoading}
                onClick={() => {
                  setActionToPerform('PREV')
                  return prev()
                }}
                disabled={currentSectionIndex === 0}
              >
                <Paragraph>Prev</Paragraph>
              </Button>
              <Button
                isLoading={isNextLoading}
                onClick={() => {
                  setActionToPerform('NEXT')
                  return next()
                }}
                disabled={!!missingFields.length}
              >
                <Paragraph>{isLastStep ? 'Save and Close' : 'Next'}</Paragraph>
              </Button>
            </Group>
            <Group flexDirection="column" spacing={1}>
              <VesselQuestionnaireMissingFields section={currentSection} />
            </Group>
          </Box>
        }
      />
    </FormProvider>
  )
}
