import { Loader, TextButton } from '@maersktankersdigital/web-components'
import { Box as MuiBox } from '@mui/material'
import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { ApiClient, ApiRoutes } from '~api'
import {
  IQuestionnaireCategory,
  IQuestionnaireField,
  IQuestionnairesResponse,
  IVesselFile,
} from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { Icon } from '~components/atoms/icon'
import { Link } from '~components/atoms/link'
import { Text } from '~components/atoms/text'
import {
  AdditionalFileBox,
  IAdditionalFileBox,
} from '~components/molecules/additional-file-box/additional-file-box'
import { IQuestionnaireListSection } from '~components/organisms/lists/vessel-questionnaires'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { generatePathName } from '~utils/generate-path-name'
import { gtm } from '~utils/gtm'
import { useStore } from '../../../context/store-provider'
import { UserRoleName, UserState } from '../../../context/user-provider'

export interface IAdditionalFilesField {
  field: IQuestionnaireField
  files?: IVesselFile[]
  initialStatus?: IAdditionalFileBox['status']
  section: IQuestionnaireListSection
  onCancelClick?: () => void
}

interface IFormFile extends IVesselFile {
  isLoading: boolean
  status: IAdditionalFileBox['status']
}

function defineFileStatus(
  file: IVesselFile,
  isMTAdmin?: boolean,
  hasVesselRole?: UserState['hasVesselRole'],
): string {
  if (file.approvedBy) {
    return 'approved'
  } else if (
    isMTAdmin ||
    (hasVesselRole &&
      (hasVesselRole(UserRoleName.MT_OPS, file.metadata.vesselId) ||
        hasVesselRole(UserRoleName.ADMIN, file.metadata.vesselId)))
  ) {
    return 'approve_or_reject'
  } else {
    return 'uploaded'
  }
}

const setInitialFiles = (
  files?: IVesselFile[],
  isMTAdmin?: boolean,
  hasVesselRole?: UserState['hasVesselRole'],
) => {
  return (
    files?.map((file) => {
      return {
        ...file,
        isLoading: false,
        status: defineFileStatus(file, isMTAdmin, hasVesselRole),
      } as IFormFile
    }) ?? []
  )
}
function findQuestionnaireField(
  response: IQuestionnairesResponse,
  name: string,
): IQuestionnaireField | undefined {
  let foundField = undefined
  response.forEach((category: IQuestionnaireCategory) => {
    return category.sections.forEach((section) => {
      return section.fields.forEach((field) => {
        if (field.id === name) {
          foundField = field
        }
      })
    })
  })

  return foundField
}

export const AdditionalFilesField: FC<IAdditionalFilesField> = ({
  field,
  section,
  onCancelClick,
}) => {
  const store = useStore()
  const { vesselId } = useParams()
  const isMTAdmin = useHasScope(ExactScopeName.superUser)

  const [files, initializeFilesState] = useState<IFormFile[]>(
    setInitialFiles(field?.files, isMTAdmin, store.User.hasVesselRole),
  )
  const [isAdditionalFileNeeded, setAdditionalFileFlag] = useState<boolean>(
    files.length === 0,
  )

  const [isFileRemoving, setFileRemovingFlag] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const removeFile = async (obsoleteFile: IVesselFile) => {
    if (obsoleteFile) {
      setFileRemovingFlag(true)
      setIsUploading(true)
      const urlParams = {
        vesselId,
        fileName: encodeURIComponent(obsoleteFile.filename),
        id: field.id,
      }
      await ApiClient.Vessels.Questionnaire.removeFile(urlParams)
        .then((response) => {
          initializeFilesState(
            setInitialFiles(
              findQuestionnaireField(response, field.id)?.files,
              isMTAdmin,
              store.User.hasVesselRole,
            ),
          )
          mutate(
            generatePathName(ApiRoutes.Vessels.Questionnaire.read, {
              vesselId,
            }),
          )
          if (files.length > 0) {
            setAdditionalFileFlag(true)
          }
          setFileRemovingFlag(false)
          setIsUploading(false)
        })
        .catch(() => {
          setFileRemovingFlag(false)
          setIsUploading(false)
        })
    }
  }

  const { register } = useFormContext()

  register(`${field.id}_files`)

  const onUpload = async (file?: File) => {
    if (file) {
      setIsUploading(true)
      const payload = {
        file,
        id: field.id,
      }

      await ApiClient.Vessels.Questionnaire.uploadFile(payload, {
        vesselId,
      })
        .then((response) => {
          initializeFilesState(
            setInitialFiles(
              findQuestionnaireField(response, field.id)?.files,
              isMTAdmin,
              store.User.hasVesselRole,
            ),
          )
          setIsUploading(false)
          setAdditionalFileFlag(false)
          mutate(
            generatePathName(ApiRoutes.Vessels.Questionnaire.read, {
              vesselId,
            }),
          )

          /* Copied from VesselQuestionnaireModal as the code isn't built for this behavior */
          const updatedSectionProgress =
            response
              .find(
                (responseCategory) =>
                  responseCategory.name === section.categoryName,
              )
              ?.sections?.find(
                (responseSection) => responseSection.id === section.id,
              )?.progress || ''

          gtm.pushEvent('section_edited', {
            section_category: section.categoryName,
            section_name: section.name,
            section_progress: updatedSectionProgress,
            vessel_data: {
              progress: response[0].questionnaireOverallProgress || '',
            },
          })
          /* End copy */
        })
        .catch(() => {
          setIsUploading(false)
        })
    }
  }

  const handleRemove = (file?: IVesselFile) => {
    if (file) removeFile(file)
  }

  const handleDownload = async (file?: IVesselFile) => {
    if (file) {
      await ApiClient.Vessels.Questionnaire.downloadFile({
        vesselId: file.metadata.vesselId,
        fileId: file.metadata.id,
        fileName: file.filename,
      }).then((response) => {
        if (response.signedUrl) {
          window.location.href = response.signedUrl
        }
      })
    }
  }

  return !isFileRemoving ? (
    <>
      {files.map((file) => {
        return (
          <Box key={file.name}>
            <AdditionalFileBox
              name={file.name}
              file={file}
              status={file.status}
              onDownload={handleDownload}
              isLoading={file.isLoading}
              onRemove={handleRemove}
            />
          </Box>
        )
      })}
      <Box>
        {isAdditionalFileNeeded ? (
          <MuiBox
            sx={
              !onCancelClick
                ? {}
                : {
                    display: 'flex',
                    flexDirection: files.length === 0 ? 'row' : 'column',
                    flexWrap: 'wrap',
                    gap: 3,
                    alignItems: files.length === 0 ? 'center' : 'normal',
                  }
            }
          >
            <AdditionalFileBox
              name="newFile"
              status="empty"
              isLoading={isUploading}
              onUpload={onUpload}
            />
            {onCancelClick && (
              <TextButton
                onClick={(e) => {
                  e.preventDefault()
                  setAdditionalFileFlag(false)
                  onCancelClick()
                }}
              >
                Cancel
              </TextButton>
            )}
          </MuiBox>
        ) : (
          <Link
            onClick={() => {
              return setAdditionalFileFlag(true)
            }}
          >
            <Box flexDirection="row" alignItems="center" mt={1.7} ml={1.7}>
              <Icon name="plus" size={30} />
              <Box ml={1}>
                <Text text="Add additional file for upload" color="blues.mid" />
              </Box>
            </Box>
          </Link>
        )}
      </Box>
    </>
  ) : (
    <Loader />
  )
}
