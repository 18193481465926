import { PoolPoints } from '~types/pool-points.types'

export const getMaxPossiblePoints = (poolPoints: PoolPoints | undefined) =>
  poolPoints?.totalPoolPoints?.range?.max ?? 0

export const getPoolPointsResultLiteral = (
  poolPoints: PoolPoints,
  result: number | string,
) =>
  `${result.toString()} out of ${getMaxPossiblePoints(
    poolPoints,
  )} possible points`
