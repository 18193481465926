import { matchPath, useLocation } from 'react-router-dom'
import { localStorageItemKeys } from '~constants/local-storage-items'
import { useGuidedToursList } from '../content/guided-tours-list'

export function useTour() {
  const location = useLocation()
  const guidedToursList = useGuidedToursList()
  const pageTour = guidedToursList.find((tour) =>
    tour.tourPaths.some((path) => matchPath({ path }, location.pathname)),
  )

  if (!pageTour) return null

  const getReplayTour = () => {
    const replayTourId = localStorage.getItem(
      localStorageItemKeys.triggeredGuidedTour,
    )

    return replayTourId === pageTour.id ? pageTour : null
  }

  const getUncompletedTour = () => {
    return pageTour.status !== 'Completed' ? pageTour : null
  }

  return getReplayTour() || getUncompletedTour()
}
