export const copyToClipboard = (content: string): void => {
  const listener = (e: ClipboardEvent) => {
    if (e.clipboardData) {
      e.clipboardData.setData('text/html', content)
      e.clipboardData.setData('text/plain', content)
    }

    e.preventDefault()
  }
  document.addEventListener('copy', listener)
  document.execCommand('copy')
  document.removeEventListener('copy', listener)
}
