import { ClickPoint, CloseIcon } from '@maersktankersdigital/web-components'
import { createContext, FC, ReactNode } from 'react'

import { Box } from '~components/atoms/box'
import { Text } from '~components/atoms/text'

interface IAddPoolPartnerModal {
  close?: () => void
  content: ReactNode
  header: string
}

interface IContextData {
  closeModal?: () => void
}

const InitialContextData: IContextData = {
  closeModal: undefined,
}

export const DashboardModalContext =
  createContext<IContextData>(InitialContextData)

export const DashboardModal: FC<IAddPoolPartnerModal> = ({
  content,
  header,
  close,
}) => {
  const closeModal = close
  return (
    <Box ph={4.5} pv={3.3}>
      <Box flexDirection="row">
        <Box pr={4.7} mb={2.4}>
          <Text
            color="blues.deep"
            textTransform="uppercase"
            weight="bold"
            text={header}
          />
        </Box>
        <Box flex={0} mt={-1.2} mr={-1.8}>
          <ClickPoint
            ariaLabel="Close"
            onClick={() => {
              if (close) {
                close()
              }
            }}
          >
            <CloseIcon fontsize={25} />
          </ClickPoint>
        </Box>
      </Box>
      <DashboardModalContext.Provider value={{ closeModal }}>
        <Box>{content}</Box>
      </DashboardModalContext.Provider>
    </Box>
  )
}
