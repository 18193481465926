import { Fragment, Suspense } from 'react'
import { Route } from 'react-router-dom'
import LoadComponent from '~components/molecules/load-component/load-component'
import { PageRoute } from './constants/page-route'
import { STATIC_PAGE_ATTRIBUTES } from './constants/routes'

export const getRouteComponents = (routeList: PageRoute[]) =>
  routeList.map((route) => {
    const Component = STATIC_PAGE_ATTRIBUTES[route]?.component || Fragment

    return (
      <Route key={route} path={route} element={<Component />}>
        {STATIC_PAGE_ATTRIBUTES[route]?.children?.map((child) => (
          <Route
            key={child.path}
            index={child.index}
            path={child.path}
            element={
              <Suspense fallback={<LoadComponent />}>
                {child.parent ? (
                  <child.parent.component {...child.parent.props}>
                    <child.element />
                  </child.parent.component>
                ) : (
                  <child.element />
                )}
              </Suspense>
            }
          />
        ))}
      </Route>
    )
  })

export const getRoutes = (routeList: PageRoute[]) =>
  routeList.map((route) => {
    const Component = STATIC_PAGE_ATTRIBUTES[route]?.component || Fragment

    return {
      path: route,
      element: (
        <Suspense>
          <Component />
        </Suspense>
      ),
    }
  })
