import { FunctionComponent, lazy } from 'react'
import CallbackPage from '~pages/auth/callback-page'
import { PageRoute } from './page-route'

const PoolPartnerPage = lazy(
  () => import('~pages/pages-behind-login/pool-partner-page/pool-partner-page'),
)
const RejectionsPage = lazy(
  () => import('~pages/pages-behind-login/rejections-page/rejections-page'),
)
const SirePage = lazy(
  () => import('~pages/pages-behind-login/sire-page/sire-page'),
)
const LandingPage = lazy(
  () => import('~pages/open-pages/landing-page/landing-page'),
)
const CdiPage = lazy(() => import('~pages/pages-behind-login/cdi/cdi-page'))

const UsersPage = lazy(
  () => import('~pages/pages-behind-login/users-page/users-page'),
)
const UserPage = lazy(
  () => import('~pages/pages-behind-login/user-page/user-page'),
)
const UnauthorizedPage = lazy(() => import('~pages/auth/unauthorized-page'))
const CreateUserPage = lazy(
  () => import('~pages/pages-behind-login/create-user-page/create-user-page'),
)
const VerifyEmailPage = lazy(
  () => import('~pages/pages-behind-login/verify-email-page/verify-email-page'),
)
const PoolPartnersPage = lazy(
  () =>
    import('~pages/pages-behind-login/pool-partners-page/pool-partners-page'),
)
const MapPage = lazy(
  () => import('~pages/pages-behind-login/position-list/map-page/map-page'),
)

const ProfilePage = lazy(
  () => import('~pages/pages-behind-login/profile-page/profile-page'),
)

type ChildrenAttributes = {
  element: React.FunctionComponent<any>
  index?: boolean
  parent?: {
    component: React.FunctionComponent<any>
    props?: any
  }
  path?: string
}

export type StaticPageAttributes = {
  children?: ChildrenAttributes[]
  component: FunctionComponent<any>
  id?: string
  title: string
}

export const STATIC_PAGE_ATTRIBUTES: Partial<
  Record<PageRoute, StaticPageAttributes>
> = {
  [PageRoute.LANDING]: {
    component: LandingPage,
    title: 'Landing',
    id: 'landing',
  },
  [PageRoute.AUTH_CALLBACK]: {
    component: CallbackPage,
    title: 'Auth Callback',
    id: 'authCallback',
  },
  [PageRoute.CREATE_USER]: {
    component: CreateUserPage,
    title: 'Create User',
    id: 'createUser',
  },
  [PageRoute.VERIFY_EMAIL]: {
    component: VerifyEmailPage,
    title: 'Verify E-mail',
    id: 'verifyEmail',
  },
  [PageRoute.POOL_PARTNERS]: {
    component: PoolPartnersPage,
    title: 'Pool Partners',
    id: 'poolPartners',
  },
  [PageRoute.USERS]: {
    component: UsersPage,
    title: 'Users',
    id: 'users',
  },
  [PageRoute.REJECTIONS]: {
    component: RejectionsPage,
    title: 'Rejections',
    id: 'rejections',
  },
  [PageRoute.SIRE]: {
    component: SirePage,
    title: 'Sire',
    id: 'sire',
  },
  [PageRoute.CDI]: {
    component: CdiPage,
    title: 'CDI',
    id: 'cdi',
  },
  [PageRoute.POOL_PARTNER]: {
    component: PoolPartnerPage,
    title: 'Pool Partner',
    id: 'poolPartner',
  },
  [PageRoute.USER]: {
    component: UserPage,
    title: 'User',
    id: 'user',
  },
  [PageRoute.UNAUTHORIZED]: {
    component: UnauthorizedPage,
    title: 'Unauthorized',
    id: 'unauthorized',
  },
  [PageRoute.MAP]: {
    component: MapPage,
    title: 'Map',
    id: 'map',
  },
  [PageRoute.PROFILE]: {
    component: ProfilePage,
    title: 'Profile',
    id: 'profile',
  },
}

// App routes that are only visible when the user is authorized
export const AUTH_ROUTES = [
  PageRoute.POOL_PARTNER,
  PageRoute.USER,
  PageRoute.POOL_PARTNERS,
  PageRoute.USERS,
  PageRoute.REJECTIONS,
  PageRoute.SIRE,
  PageRoute.CDI,
  PageRoute.FEATURE_FLAG,
  PageRoute.POSITION_LIST,
  PageRoute.MAP,
  PageRoute.PROFILE,
]

export const UNAUTH_ROUTES = [
  PageRoute.LANDING,
  PageRoute.AUTH_CALLBACK,
  PageRoute.UNAUTHORIZED,
  PageRoute.CREATE_USER,
]
