import { useAuth0 } from '@auth0/auth0-react'
import { Navigate, useLocation } from 'react-router-dom'
import ErrorDisplay from '~components/layout/error/error-display/error-display'
import LoadComponent from '~components/molecules/load-component/load-component'
import { useGetMe } from '~hooks/queries/me/use-get-me'

export function PageNotFound() {
  const { pathname } = useLocation()
  const { isAuthenticated } = useAuth0()
  const { data, isLoading, isError } = useGetMe()
  const isIdPage = pathname.includes('panel/404')

  if (isLoading) {
    return <LoadComponent />
  }

  if (!isIdPage && isAuthenticated && !isError && data && !data?.isInternal) {
    return <Navigate to="/panel/404" />
  }

  return (
    <ErrorDisplay
      heading="Sorry, we couldn't find this page"
      text="The requested data doesn't exist"
      isPageError
    />
  )
}
