import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { Modal } from '~components/molecules/modal/modal'
import { CookieBannerOpener } from './sub-components/cookie-banner-opener'
import { CookieDeclaration } from './sub-components/cookie-declaration'
import { CookieSettings } from './sub-components/cookie-settings'
import { getStoredCookies } from './utils/get-stored-cookies'

export enum Cookie {
  necessary = 'strictly necessary',
  statistical = 'statistical',
}

export function CookieBanner() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContentPage, setModalContentPage] = useState<'1' | '2'>('1')

  useEffect(() => {
    const storedCookies = getStoredCookies()
    if (!storedCookies.length) {
      setIsModalOpen(true)
    }
  }, [])

  return (
    <>
      {!isModalOpen && (
        <CookieBannerOpener onClickHandler={() => setIsModalOpen(true)} />
      )}
      <Box
        sx={{ zIndex: 10001 }}
        component={Modal}
        open={isModalOpen}
        title={
          modalContentPage === '1'
            ? 'This website uses cookies'
            : 'Cookie declaration'
        }
      >
        <>
          {modalContentPage === '1' && (
            <CookieSettings
              onClickHandler={() => setModalContentPage('2')}
              closeModal={() => setIsModalOpen(false)}
            />
          )}
          {modalContentPage === '2' && (
            <CookieDeclaration
              onClickHandler={() => setModalContentPage('1')}
              closeModal={() => setIsModalOpen(false)}
            />
          )}
        </>
      </Box>
    </>
  )
}
