import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { tenMinutes } from '~constants/constants'
import {
  FilterParamNameEnum,
  StatusFilterOptionValue,
} from '~pages/pages-behind-login/vessels-page/vessels-page.types'
import { apiBase } from '~utils/base-url'
import {
  getFeatureToggle,
  harryPotterThemeSupportEnabled,
} from '~utils/feature-toggles'
import { useFetch } from '~utils/fetch-request'

export interface GetVesselsResItem {
  vesselName: string
  imoNumber: string
  pool: string
  poolPartner?: string
  targetDate: Date
  status: StatusFilterOptionValue
  progress: number
  tcin?: Tcin[]
  expiredCertificates?: Certificate[]
  missingCertificates?: Certificate[]
  currentVoyage: CurrentVoyage
  vesselId: string
  vesselCode: string
  poolPartnerName?: string
  deliveryDate?: Date
  redeliveryDate?: Date
  onboardedDate?: Date
  archivedAt?: string
  archivedReason?: string
}

interface Certificate {
  vesselName: string
  type: string
  certificate: string
}

interface CurrentVoyage {
  status?: CurrentVoyageStatus
  chartererShortName?: string
  department?: string
  ballastPort?: string
  loadPort?: string
  dischargePort?: string
}

export enum CurrentVoyageStatus {
  Commenced = 'Commenced',
  Scheduled = 'Scheduled',
}

interface Tcin {
  deliveryDateLocal: Date
  redeliveryDateLocal: Date
  counterpartyShortName: string
  status: TcinStatus
  vesselCode: string
}

export enum TcinStatus {
  Delivered = 'Delivered',
  Redelivered = 'Redelivered',
}

interface useGetVesselsProps {
  poolName?: string
  status?: StatusFilterOptionValue
  queryDisabled?: boolean
  easterEgg?: boolean
}

export function useGetVessels(props: useGetVesselsProps = {}) {
  const fetchRequest = useFetch<GetVesselsResItem[]>()
  const [searchParams] = useSearchParams()
  const harryPotterThemeParam = searchParams.get(
    FilterParamNameEnum.harryPotterTheme,
  )

  const url = constructUrl({ ...props, easterEgg: !!harryPotterThemeParam })
  return useQuery({
    queryKey: ['/vessels', { poolName: props.poolName, status: props.status }],
    queryFn: () => fetchRequest(`${apiBase()}${url}`),
    staleTime: tenMinutes,
    enabled: !props?.queryDisabled,
  })
}

function constructUrl({
  poolName,
  status,
  easterEgg,
}: useGetVesselsProps = {}) {
  let url = '/vessels'
  const params = new URLSearchParams()

  if (poolName) {
    params.append('pool', poolName)
  }

  if (status) {
    params.append('status', status)
  }

  if (easterEgg && getFeatureToggle(harryPotterThemeSupportEnabled)) {
    params.append('easteregg', 'true')
  }

  if (params.toString()) {
    url += `?${params.toString()}`
  }

  return url
}
