import { Select } from '@maersktankersdigital/web-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import AnimatedHeart from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/vessel-name-content/animated-heart'
import { replaceStringInPathname } from '~pages/pages-behind-login/vessel-page/utils'
import { capitalizeStringWords } from '~utils/capitalize-string-words'

export function FavoritesSelect() {
  const { vesselId } = useParams()
  const navigate = useNavigate()
  const { data } = useGetMe()
  const favoriteVessels = data?.favoriteVesselsNames || []

  if (!favoriteVessels.length) {
    return
  }

  function onChangeHandler(e: any, value: string, name: string) {
    const path =
      vesselId && value ? replaceStringInPathname(vesselId, value) : undefined
    if (path) {
      navigate(path)
    }
  }

  const selectOptions = favoriteVessels
    .map((item) => ({
      label: capitalizeStringWords(item.vesselName),
      value: item._id,
      icon: <AnimatedHeart filled />,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const favorite = selectOptions.find((option) => option.value === vesselId)

  return (
    <Select
      placeholder="All"
      label="Favorite vessels"
      name="favorite-vessels"
      options={selectOptions}
      value={favorite?.value || ''}
      onChange={onChangeHandler}
    />
  )
}
