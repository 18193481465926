import { IUserInfoReadResponse } from '~api/user/read'
import { ExactScopeName } from '~hooks/permissions/use-has-scope'

export const dummyUserVt: IUserInfoReadResponse = {
  sub: 'auth0|123',
  email: 'johndoe@example.com',
  name: 'John Doe',
  ToSAcceptedDate: '2024-08-16T08:07:35.080Z',
  settings: {
    vessels: {
      favourites: [],
    },
    guidedTours: [
      {
        id: '1',
        status: 'Started',
      },
    ],
  },
  roles: [
    {
      entity: 'poolpartner',
      id: 'CID',
      role: 'user',
    },
    {
      entity: 'vessel',
      id: '66ebfcaad043f6111ac0d57a',
      role: 'poolpartner',
    },
    {
      entity: 'vessel',
      id: 'G6B',
      role: 'poolpartner',
    },
    {
      entity: 'Pool Partner',
    },
  ],
  scope: [
    'poolpartner:CID:user',
    'vessel:66ebfcaad043f6111ac0d57a:poolpartner',
    'vessel:G6B:poolpartner',
    'Pool Partner',
    ExactScopeName.maerskTankersUser,
  ],
  isInternal: true,
  poolPartnerKeys: ['CID'],
  activePools: ['MR'],
  profitCenterPools: ['Handy', 'LR2', 'MR'],
  favoriteVesselsNames: [],
}
