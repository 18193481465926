import { useAuth0 } from '@auth0/auth0-react'
import { TopBarProvider } from '@maersktankersdigital/web-components'
import { Box, Toolbar } from '@mui/material'
import { ReactNode } from 'react'
import LoadComponent from '~components/molecules/load-component/load-component'
import { useNavAndFooterConstants } from '../../hooks/use-nav-and-footer-constants'
import ScrollToTop from '../../utils/scroll-to-top'
import { Footer } from './footer/footer'
import { Navigation } from './navigation/navigation'
import { TopNavigationOpenPages } from './navigation/top-navigation/top-navigation-open-pages'

export default function NavigationAndFooter({
  children,
}: {
  children?: ReactNode
}) {
  const { isAuthenticated, isLoading } = useAuth0()
  const { footerHeight, topNavigationHeight, leftNavigationWidth } =
    useNavAndFooterConstants()

  if (isLoading) {
    return <LoadComponent />
  }

  if (!isAuthenticated) {
    return (
      <Box sx={{ position: 'relative', minHeight: '100vh' }}>
        <ScrollToTop />
        <TopNavigationOpenPages />
        <Box
          component="main"
          sx={{
            pb: footerHeight,
          }}
        >
          {children}
        </Box>
        <Footer withOutLeftNav />
      </Box>
    )
  }

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      <ScrollToTop />
      <TopBarProvider>
        <Navigation />
      </TopBarProvider>
      <Box
        component="main"
        sx={{
          width: '100%',
          pb: footerHeight,
        }}
      >
        <Toolbar
          sx={{
            minHeight: topNavigationHeight,
          }}
        />
        <Box sx={{ ml: leftNavigationWidth }}>{children}</Box>
      </Box>
      <Footer />
    </Box>
  )
}
