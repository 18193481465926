import {
  Input,
  Label,
  Select,
  theme,
} from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

export const StyledInput = styled(Input)`
  width: 300px;
`
export const StyledSelect = styled(Select)`
  width: 300px;
`

export const StyledLabel = styled(Label)`
  display: block;
`

export const StyledFormFieldWrapper = styled.div`
  margin-bottom: ${theme.SPACINGS[5]};
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
`

export const StyledForm = styled.div`
  min-height: 100%;
  max-width: 80%;
  @media only screen and (${theme.BREAKPOINTS.sm}) {
    & ${StyledSelect} {
      width: 344px;
    }

    & ${StyledInput} {
      width: 344px;
    }
  }
`
