import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from 'src/constants/constants'
import { Voyage } from '~types/itinerary.types'
import { useFetch } from '~utils/fetch-request'
import { apiBase } from '~utils/base-url'

export function useGetVoyages(
  vesselId: string | undefined,
  filtersQuery?: string,
) {
  const fetchRequest = useFetch<Voyage[]>()

  const url = `${apiBase()}/voyages?vesselId=${vesselId}${filtersQuery ? filtersQuery : '&limit=10'}`

  return useQuery({
    queryKey: [url],
    queryFn: () => fetchRequest(url),
    staleTime: tenMinutes,
    enabled: !!vesselId,
  })
}
