import { useAuth0 } from '@auth0/auth0-react'
import LoadComponent from '~components/molecules/load-component/load-component'
import { Typography } from '@mui/material'
import Wrapper from '~components/layout/wrapper/wrapper'

const CallbackPage = () => {
  const { error } = useAuth0()
  console.log('🚀 ~ CallbackPage ~ error:', error)

  if (error) {
    return (
      <Wrapper>
        <Typography sx={{ mt: 6 }}>
          There was an unexpected login error. Please click on the "Log In"
          button on the top right to try again.
        </Typography>
        <Typography sx={{ mt: 4, fontWeight: 'bold' }}>
          Want to report the issue? Click the "Report an Issue" widget on the
          right.
        </Typography>
      </Wrapper>
    )
  }
  return <LoadComponent />
}

export default CallbackPage
