import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { Z_INDEX } from '~types/z-index'

import { pageHeaderHeights } from 'src/constants/constants'

export const StyledPageHeader = styled.header`
  position: sticky;
  background: ${COLORS.greys.ultraLight};
  top: ${pageHeaderHeights.mobile};
  left: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: ${Z_INDEX.PageHeader};

  @media only screen and (${theme.BREAKPOINTS.sm}) {
    top: ${pageHeaderHeights.desktop};
  }
`

export const StyledHeadlineAndActionsContainer = styled.div`
  display: flex;
  column-gap: ${theme.SPACINGS[3]};
  margin-bottom: ${theme.SPACINGS[5]};

  & > div {
    padding: ${theme.SPACINGS[1]} 0;
  }
  h1 {
    margin: 0;
  }
`
