import { useEffect, useState } from 'react'
import Joyride from 'react-joyride'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import {
  GuidedTour as GuidedTourI,
  IUserInfoReadResponse,
} from '~api/user/read'
import { localStorageItemKeys } from '~constants/local-storage-items'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { usePatchMeVT } from '~hooks/queries/me/use-patch-me-vt'
import { COLORS } from '~theme/colors'
import { getFeatureToggle, guidedTourEnabled } from '~utils/feature-toggles'
import { GuidedTourListItem } from './content/guided-tours-list'
import { useTour } from './hooks/use-tour'
import { CustomTooltip } from './sub-components/custom-tooltip'

const customStyles = {
  options: {
    primaryColor: COLORS.primary.maerskBlue,
    zIndex: 1202,
  },
}

function getInitialStepIndex() {
  const stepIndex = localStorage.getItem(
    localStorageItemKeys.guidedTourStepIndex,
  )

  return stepIndex ? Number(stepIndex) : undefined
}

export function GuidedTour() {
  const location = useLocation()
  const [startTour, setStartTour] = useState(false)
  const tour = useTour()

  const initialStepIndex = getInitialStepIndex()
  const tourDelay = !initialStepIndex ? 100 : 1000

  useEffect(() => {
    const isTourStartPath = !tour?.startTourPath
      ? false
      : matchPath({ path: tour?.startTourPath }, location.pathname)

    if (!tour || (!initialStepIndex && !isTourStartPath)) {
      return
    }

    setTimeout(() => {
      setStartTour(true)
    }, tourDelay)
  }, [location, tour, initialStepIndex, tourDelay])

  if (!getFeatureToggle(guidedTourEnabled)) return null

  return tour?.content?.length && startTour ? (
    <TourRenderer tour={tour} initialStepIndex={initialStepIndex} />
  ) : null
}

function TourRenderer({
  tour,
  initialStepIndex,
}: {
  tour: GuidedTourListItem
  initialStepIndex: number | undefined
}) {
  const { data: me } = useGetMe()
  const [run, setRun] = useState(true)
  const navigate = useNavigate()
  const { mutateAsync: patchMe } = usePatchMeVT()

  const tourSteps = tour.content

  function handleCallback(data: any) {
    const {
      action,
      step: { data: { next = null, previous = null } = {} } = {},
      type,
      index,
    } = data

    const isLastStep = index === tourSteps.length - 1

    if (isLastStep && tour.status !== 'Completed') {
      patchMe(
        constructPayload({ me, tour: { id: tour.id, status: 'Completed' } }),
      )
      localStorage.removeItem(localStorageItemKeys.guidedTourStepIndex)
      localStorage.removeItem(localStorageItemKeys.triggeredGuidedTour)
    }

    if (action === 'update') {
      if (!localStorage.getItem(localStorageItemKeys.triggeredGuidedTour))
        localStorage.setItem(localStorageItemKeys.triggeredGuidedTour, tour.id)
    }

    if (action === 'close' || action === 'skip') {
      localStorage.removeItem(localStorageItemKeys.guidedTourStepIndex)
      localStorage.removeItem(localStorageItemKeys.triggeredGuidedTour)

      if (action === 'close' && isLastStep) {
        setRun(false)
      }
      return
    }

    if (type === 'step:after') {
      if (
        index === 0 &&
        tour.status !== 'Started' &&
        tour.status !== 'Completed'
      ) {
        patchMe(
          constructPayload({ me, tour: { id: tour.id, status: 'Started' } }),
        )
      }
      if (next && action === 'next') {
        localStorage.setItem(
          localStorageItemKeys.guidedTourStepIndex,
          index + 1,
        )
        navigate(next)
      }

      if (previous && action === 'prev') {
        localStorage.setItem(
          localStorageItemKeys.guidedTourStepIndex,
          (index - 1).toString(),
        )
        navigate(previous)
      }
    }
  }

  return (
    <Joyride
      steps={tourSteps}
      tooltipComponent={CustomTooltip}
      stepIndex={initialStepIndex}
      continuous
      styles={customStyles}
      showSkipButton
      scrollOffset={100}
      callback={handleCallback}
      run={run}
    />
  )
}

function constructPayload({
  me,
  tour,
}: {
  me?: IUserInfoReadResponse
  tour: GuidedTourI
}) {
  const tours = me?.settings?.guidedTours || []
  const updatedTours = tours.filter((t) => t.id !== tour.id)
  return {
    settings: { guidedTours: [...updatedTours, tour] },
  }
}
