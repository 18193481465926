import { TopBarProvider } from '@maersktankersdigital/web-components'
import { useEffect } from 'react'
import StoreProvider from 'src/context/store-provider'
import UserProvider from 'src/context/user-provider'
import { App } from './app'

export function VtRoutesWrapper() {
  const host = window.location.host

  useEffect(() => {
    const setFavicon = () => {
      const favicon = document.getElementById('favicon') as HTMLAnchorElement

      if (host.includes('localhost') || host.includes('test')) {
        favicon.href = '/favicon-test.svg'
      }
      if (host.includes('stage')) {
        favicon.href = '/favicon-stage.svg'
      }
    }
    setFavicon()
  }, [host])

  return (
    <UserProvider>
      <StoreProvider>
        <TopBarProvider>
          <App />
        </TopBarProvider>
      </StoreProvider>
    </UserProvider>
  )
}
