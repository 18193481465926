import { Divider } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IQuestionnaireField } from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { IQuestionnaireListSection } from '~components/organisms/lists/vessel-questionnaires'
import { VesselQuestionaireFieldTypeResolver } from '~components/organisms/modals/earnings-simulator/field-type-resolver'

export const VesselQuestionnaireFieldWrapper: FC<{
  field: IQuestionnaireField
  index: number
  section: IQuestionnaireListSection
}> = ({ field, index, section }) => {
  const { watch, setValue } = useFormContext()
  const [shouldShowField, setShouldShowField] = useState(!field.dependsOn)
  const anotherFieldValue = field.dependsOn
    ? watch(
        `${field.dependsOn.id}_value`,
        section.fields.find(
          (sectionField) => sectionField.id === field.dependsOn?.id,
        )?.value,
      )
    : undefined

  useEffect(() => {
    if (anotherFieldValue !== undefined && field.dependsOn) {
      setShouldShowField(anotherFieldValue === field.dependsOn.value)
    }
  }, [anotherFieldValue, section])

  useEffect(() => {
    if (shouldShowField && field.dependsOn) {
      setValue(`${field.id}_value`, field.value || '')
      setRerender(!rerender)
    }
  }, [shouldShowField])

  const [rerender, setRerender] = useState(false)

  return shouldShowField ? (
    <Box mt={6}>
      <VesselQuestionaireFieldTypeResolver section={section} field={field} />
      <Divider sx={{ mt: 5 }} />
    </Box>
  ) : null
}
