import { theme, typographyStyles } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { AreaHeader } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table.styles'

export const ExposureAreaHeader = styled(AreaHeader)`
  align-items: flex-end;
`

export const VesselsNumber = styled.div`
  ${typographyStyles.headline2};
  margin-top: ${theme.SPACINGS[6]};
  margin-bottom: ${theme.SPACINGS[3]};

  & span {
    ${typographyStyles.graphCaption}
    color: ${COLORS.primary.maerskBlue}
  }
`
