import { generatePath, useParams } from 'react-router-dom'
import { useActivePoolContext } from '../context/active-pool-context'
import { useGetMe } from './queries/me/use-get-me'

export function useConstructLinkWithPool(route: string) {
  const params = useParams()
  const { activePool } = useActivePoolContext()
  const { data: me } = useGetMe()

  const usersPools = me?.activePools || me?.profitCenterPools

  if (!usersPools || usersPools.length === 0) {
    return '/404'
  }
  // the local storage item is set in the V1 page.
  // It is useful when navigating between v1 and v2 pages.
  const poolNameLS = localStorage.getItem('poolName')

  if (params.pool) {
    return generatePath(route, {
      pool: params.pool,
    }).toLowerCase()
  }

  if (activePool) {
    return generatePath(route, {
      pool: activePool,
    }).toLowerCase()
  }

  if (poolNameLS) {
    return generatePath(route, {
      pool: poolNameLS.toLowerCase(),
    }).toLowerCase()
  }

  return generatePath(route, {
    pool: usersPools[0].toLocaleLowerCase(),
  }).toLowerCase()
}
