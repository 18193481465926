import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'
import { IQuestionnairesResponse } from '~api/vessels/read'

export interface GetVesselRes {
  _id: number
  imoNumber: string
  pool: string
  poolPartner: string
  targetDate?: Date | string
  track: string
  partnerManager: string
  status: string
  createdAt: Date | string
  vesselName: string
  progress: number
  tcin?: {
    deliveryDateLocal: Date
    redeliveryDateLocal: string
    counterpartyShortName: string
    status: string
    vesselCode: string
  }[]
  bunkerconsumption: {
    ballastTonsPerDay: number
    ladenTonsPerDay: number
    updatedAt: Date
    ladenTonsPerDayPoolAvg: number
    ladenTonsPerDayPoolRating?: 'low' | 'medium' | 'high'
    poolAvgCalculatedAt: Date
  }
  updatedAt: Date
  vesselId: string
  vesselCode: string
  euaInfo?: {
    euaAccountName: string
    euaAccountNumber: string
  }
  onboardedDate: string

  opsCoordinatorName: string
  partnerManagerUser: {
    name?: string
    sub: string
  }
  contactDetails: {
    type: string
  }[]
  deliveryDate?: string
  redeliveryDate?: string
  voyageManager: string
  voyageManagerUser: {
    name?: string
    sub: string
  }
  questionnaire: IQuestionnairesResponse
  questionnaireDeadlines: {
    taxrelatedtrading: Date
    certification: Date
    ownerspoliciesprocedures: Date
    factorsthatmayeffecttradability: Date
    intakedetails: Date
    operationaldetails: Date
    'geographicalrestrictions-nonpoolpointrelated': Date
    securityrequirements: Date
    scrubberdetails: Date
    engineloadsettings: Date
    taxrelatedtrading2: Date
    ownerspoliciesprocedures_specificports: Date
    certification_empty: Date
    'ownerspoliciesprocedures-commercial-management': Date
    'securityrequirements-commercial-management': Date
  }
}

export function useGetVessel({ vesselId }: { vesselId: string | undefined }) {
  const fetchRequest = useFetch<GetVesselRes>()
  const url = `/vessels/${vesselId}`
  return useQuery({
    queryKey: [url],
    queryFn: () => fetchRequest(`${apiBase()}${url}`),
    staleTime: tenMinutes,
    enabled: !!vesselId,
  })
}
